@font-face {
    font-family: 'Poppins-Regular';
    src: url('./Poppins-Regular.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('./Poppins-Bold.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'RockSalt-Regular';
    src: url('./RockSalt-Regular.ttf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'PlusJakartaSans-Regular';
    src: url('./PlusJakartaSans-Regular.ttf');
    font-weight: 100;
    font-style: normal;
}


@font-face {
    font-family: 'PlusJakartaSans-Bold';
    src: url('./PlusJakartaSans-Bold.ttf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('./Roboto-Regular.ttf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('./Roboto-Bold.ttf');
    font-weight: 100;
    font-style: normal;
}