@media (min-width: 1301px) and (max-width: 1422px) {


    .containerLetterParagraph {
        margin-top: 9vh;
        width: 81%;

    }

    .containerPhotos {
        margin-top: 15vh;
        height: 100%;
    }


    .containerTextMoreImage {
        margin-top: -25px;
    }

    .defineContainerText {

        width: 100%;

    }

    .leftContainer {

        padding-top: 5rem;
        margin-top: 5vh;
        padding-bottom: 20vh;
        text-align: left;
        background-color: transparent;

    }

    .containerAdditionalText {
        width: 77%;
    }

    .firstTextLeftContainer {
        line-height: 2rem;
        font-size: 30px;
        font-family: 'Poppins-Regular';
        font-weight: bold;

    }

    .firstTextLeftContainerEnglish {
        font-size: 1.7rem
    }



    .contenedorLetterBlueContainer {
        margin-top: -0.8rem;
        display: flex;
        flex-direction: row;
        align-items: center;

    }

    .thirdText {
        font-family: 'Poppins-Regular';
        font-weight: bold;
        font-size: 30px;
        background-color: #00FFD7;

    }

    .secondTextLeftContainer {
        font-size: 30px;
        font-family: 'Poppins-Regular';
        font-weight: bold;
        line-height: 0rem;
        /* margin-bottom: 5px; */
    }

    .leftContainer {

        width: 50%;
        height: 80%;

    }

    .textH3 {
        margin-top: 15px;
        font-family: 'Poppins-Regular';
        font-size: 20px;
        margin-bottom: -10vh;
        padding-bottom: 0;
        padding-top: 0;
    }

    .fourthText {
        font-size: 0.9rem;
        font-family: 'Poppins-Regular';

    }

    .additionalText {
        font-size: 18px;
        text-align: left;
    }

    .eyesImage {

        width: 13%;
        position: relative;
        margin: 8% 21% 5% 0%;
        transform: rotate(7deg);

    }

    .boxTwo {
        overflow: hidden;
        position: absolute;
        width: 60%;
        height: 40%;
        top: 68%;
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        left: 62%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 2;
        border-radius: 10%;
    }

    .boxTwo img {
        position: relative;
        width: 105%;
        height: 101%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
    }

    .boxOne img {
        position: relative;
        width: 115%;
        height: 155%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-15deg);
    }

    .boxOne {
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        overflow: hidden;
        position: relative;
        width: 60%;
        height: 40%;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 1;
        border-radius: 10%;

    }

    .iconoUserMap {
        width: 40%;
        z-index: 3;
        position: absolute;
        bottom: 68%;
        left: 62%;

    }

    .iconoGirl {
        width: 40%;
        z-index: 3;
        position: absolute;
        top: 35%;
        left: 5%;

    }


    .letterParagraph {
        font-size: 18px;
        text-align: left;
    }


    .fourthText {
        font-family: 'Poppins-Regular';
        font-size: 18px;
        color: #028470;

    }





}

@media (min-width: 1350px) and (min-height: 850px) {

    .leftContainer {
        margin-top: 55px;
    }

    .containerAdditionalText {
        width: 75%;
    }

}

@media (min-width: 1422px) and (max-width:1506px) {


    .containerLetterParagraph {
        margin-top: 0vh;
        width: 81%;

    }

    .containerAdditionalText {
        width: 76%;
        padding-bottom: 27vh;
    }

    .containerTextMoreImage {
        margin-top: -10px;
    }

    .defineContainerText {

        width: 100%;

    }



    .firstTextLeftContainer,
    .firstTextLeftContainerEnglish {
        line-height: 2rem;
        font-size: 30px;
        font-family: 'Poppins-Regular';
        font-weight: bold;

    }

    .firstTextLeftContainerEnglish {
        font-size: 30px
    }



    .contenedorLetterBlueContainer {
        margin-top: -1.9rem;

    }

    .thirdText,
    .thirdTextEnglish {
        font-family: 'Poppins-Regular';
        font-weight: bold;
        font-size: 30px;
        background-color: #00FFD7;
    }



    .secondTextLeftContainer,
    .secondTextLeftContainerEnglish {
        font-size: 30px;
        font-family: 'Poppins-Regular';
        font-weight: bold;
        line-height: 1rem;
     

    }



    .secondTextLeftContainerEnglish {
        font-size: 2rem
    }

    .textH3 {
        font-family: 'Poppins-Regular';

        font-size: 24px;
        line-height: 0rem;
        margin-top: 25px;

    }

    .letterParagraph {
        font-size: 18px;
        text-align: left;
        margin-top: 0;
        margin-left: -5px;
        padding-left: 0px;
    }

    .fourthText {
        font-size: 18px !important;
        font-family: 'Poppins-Regular';

    }

    .additionalText {
        font-size: 18px;
        text-align: left;
        margin-top: 1.5rem;
    }

    .eyesImage {

        width: 13%;

        position: relative;
        margin: 8% 13% 5% 0%;
        transform: rotate(7deg);

    }

    .boxTwo {
        overflow: hidden;
        position: absolute;
        width: 60%;
        height: 30%;
        top: 58%;
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        left: 62%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 2;
        border-radius: 10%;
    }

    .boxTwo img {
        position: relative;
        width: 115%;
        height: 101%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(0deg);
    }

    .boxOne img {
        position: relative;
        width: 120%;
        height: 155%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(-15deg);
    }

    .boxOne {
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        overflow: hidden;
        position: relative;
        width: 60%;
        height: 30%;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 1;
        border-radius: 10%;

    }

    .iconoUserMap {
        width: 40%;
        z-index: 3;
        position: absolute;
        bottom: 68%;
        left: 62%;

    }

    .iconoGirl {
        width: 40%;
        z-index: 3;
        position: absolute;
        top: 35%;
        left: 5%;

    }




    .fourthText {
        font-family: 'Poppins-Regular';
        font-size: 35px;
        color: #028470;

    }

    .leftContainer {
        user-select: none;
        font-family: 'Poppins-Regular';
        font-size: 35px;
        width: 50%;
        height: 90%;
        margin-top: 1rem;
        padding-top: 115px;

    }

}

@media (min-width: 1506px) and (max-width: 1606px) and (min-height: 600px) and (max-height: 900px) {
    .containerLetterParagraph {
        margin-top: 9vh;
        width: 85%;

    }

    .containerAdditionalText {
        width: 78%;
        padding-bottom: 25vh;
    }

    .containerTextMoreImage {
        margin-top: -10px;
    }

    .defineContainerText {

        width: 100%;

    }

    .leftContainer {

        margin-top: 1rem;
        padding-top: 125px;
        padding-bottom: 25px;

    }

    .firstTextLeftContainer,
    .firstTextLeftContainerEnglish {
        line-height: 4rem;
        font-size: 30px;
        font-family: 'Poppins-Regular';
        font-weight: bold;


    }

    .firstTextLeftContainerEnglish {
        font-size: 2.0rem
    }

    .containerBlueLetter {
        display: flex;
        align-items: center;
        margin-top: 0.4rem;

        height: 2rem;

    }

    .contenedorLetterBlueContainer {
        margin-top: -0.9rem;

    }

    .thirdText,
    .thirdTextEnglish {
        font-family: 'Poppins-Regular';
        font-weight: bold;
        font-size: 30px;
        background-color: #00FFD7;
    }



    .secondTextLeftContainer,
    .secondTextLeftContainerEnglish {
        font-size: 30px;
        font-family: 'Poppins-Regular';
        font-weight: bold;
        line-height: 1rem;


    }



    .secondTextLeftContainerEnglish {
        font-size: 2.0rem
    }

    .leftContainer {

        width: 50%;
        height: 80%;

    }

    .textH3 {
        font-family: 'Poppins-Regular';
        font-size: 30px;
        line-height: 0rem;
        margin-top: 25px;
        padding-bottom: 0;
    }

    .letterParagraph {
        font-size: 20px;
        text-align: left;
        margin-top: -6vh;
        margin-left: 1vh;
    }

    .fourthText {
        font-size: 18px;
        font-family: 'Poppins-Regular';

    }

    .additionalText {
        font-size: 20px;
        text-align: left;
        margin-top: 20px;
    }

    .eyesImage {
        width: 13%;
        position: relative;
        margin: 7% 20% 5% 0%;
        transform: rotate(7deg);
    }

    .boxTwo {
        overflow: hidden;
        position: absolute;
        width: 60%;
        height: 30%;
        top: 58%;
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        left: 62%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 2;
        border-radius: 10%;
    }

    .boxTwo img {
        position: relative;
        width: 115%;
        height: 101%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(0deg);
    }

    .boxOne img {
        position: relative;
        width: 120%;
        height: 155%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(-15deg);
    }

    .boxOne {
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        overflow: hidden;
        position: relative;
        width: 60%;
        height: 30%;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 1;
        border-radius: 10%;

    }

    .iconoUserMap {
        width: 40%;
        z-index: 3;
        position: absolute;
        bottom: 68%;
        left: 62%;

    }

    .iconoGirl {
        width: 40%;
        z-index: 3;
        position: absolute;
        top: 35%;
        left: 8%;

    }


    .letterParagraph {
        user-select: none;
        font-family: 'Poppins-Regular';
        font-size: 20px;

    }

    .fourthText {
        font-family: 'Poppins-Regular';
        font-size:20px;
        color: #028470;

    }

}

@media (min-width: 1600px) and (max-width:1821px) and (max-height: 880px) {
    .containerLetterParagraph {
        width: 83%;

    }

    .containerAdditionalText {
        width: 78%;
        padding-bottom: 25vh;
    }

    .containerTextMoreImage {
        margin-top: -10px;
    }

    .defineContainerText {

        width: 100%;

    }

    .leftContainer {

        margin-top: 1rem;
        padding-top: 50px;

    }

    .firstTextLeftContainer,
    .firstTextLeftContainerEnglish {
        line-height: 4rem;
        font-size: 30px;
        font-family: 'Poppins-Regular';
        font-weight: bold;


    }

    /* .firstTextLeftContainerEnglish {
        font-size: 30px
    } */

    .containerBlueLetter {
        display: flex;
        align-items: center;
        margin-top: 0.4rem;

        height: 2rem;

    }

    .contenedorLetterBlueContainer {
        margin-top: -0.9rem;

    }

    .thirdText,
    .thirdTextEnglish {
        font-family: 'Poppins-Regular';
        font-weight: bold;
        font-size: 30px;
        background-color: #00FFD7;
    }



    .secondTextLeftContainer,
    .secondTextLeftContainerEnglish {
        font-size: 30px;
        font-family: 'Poppins-Regular';
        font-weight: bold;
        line-height: 1rem;


    }



    .secondTextLeftContainerEnglish {
        font-size: 2.0rem
    }

    .leftContainer {

        width: 50%;
        height: 95%;

    }

    .textH3 {
        font-family: 'Poppins-Regular';
        font-size: 30px;
        line-height: 0rem;
        margin-top: 15px;
    }

    .letterParagraph {

        text-align: left;
    }

    .fourthText {
        font-family: 'Poppins-Regular';
    }

    .additionalText {
        font-size: 20px;
        text-align: left;
        margin-top: 1.5rem;
    }

    .eyesImage {

        width: 13%;

        position: relative;
        margin: 7% 20% 5% 0%;
        transform: rotate(7deg);

    }

    .boxTwo {
        overflow: hidden;
        position: absolute;
        width: 60%;
        height: 30%;
        top: 58%;
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        left: 62%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 2;
        border-radius: 10%;
    }

    .boxTwo img {
        position: relative;
        width: 115%;
        height: 101%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(0deg);
    }

    .boxOne img {
        position: relative;
        width: 120%;
        height: 155%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(-15deg);
    }

    .boxOne {
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        overflow: hidden;
        position: relative;
        width: 60%;
        height: 30%;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 1;
        border-radius: 10%;

    }

    .iconoUserMap {
        width: 40%;
        z-index: 3;
        position: absolute;
        bottom: 68%;
        left: 62%;

    }

    .iconoGirl {
        width: 40%;
        z-index: 3;
        position: absolute;
        top: 35%;
        left: 8%;

    }


    .letterParagraph {
        user-select: none;
        font-family: 'Poppins-Regular';
        font-size: 20px;
        margin-left: 0;

    }

    .fourthText {
        font-family: 'Poppins-Regular';
        font-size: 20px;
        color: #028470;

    }

}


@media (min-width: 1600px) and (max-width:1821px) and (min-height: 1000px) {
    .containerLetterParagraph {
        margin-top: 9vh;
        width: 85%;

    }

    .containerAdditionalText {
        width: 78%;
        padding-bottom: 25vh;
    }

    .containerTextMoreImage {
        margin-top: -10px;
    }

    .defineContainerText {

        width: 100%;
        margin-bottom: -80px;

    }

    .leftContainer {
    
        margin-top: 5rem;
        padding-top: 50px;

    }

    .firstTextLeftContainer,
    .firstTextLeftContainerEnglish {
        line-height: 4rem;
        font-size: 40px;
        font-family: 'Poppins-Regular';
        font-weight: bold;
        padding-left: 26px;
        padding-left: 15px;
    }

    .firstTextLeftContainerEnglish {
        font-size: 2.0rem
    }

    .containerBlueLetter {
        display: flex;
        align-items: center;
        margin-top: 0.4rem;

        height: 2rem;

    }

    .contenedorLetterBlueContainer {
        margin-top: -0.9rem;

    }

    .thirdText,
    .thirdTextEnglish {
        font-family: 'Poppins-Regular';
        font-weight: bold;
        font-size: 40px;
        background-color: #00FFD7;
    }



    .secondTextLeftContainer,
    .secondTextLeftContainerEnglish {
        font-size: 40px;
        font-family: 'Poppins-Regular';
        font-weight: bold;
        line-height: 1rem;
     

    }



    .secondTextLeftContainerEnglish {
        font-size: 2.0rem
    }

    .leftContainer {

        width: 50%;
        height: 95%;

    }

    .textH3 {
        font-family: 'Poppins-Regular';

        font-size: 30px;
        line-height: 0rem;
        margin-top: 25px;
    }

    .letterParagraph {
        font-size: 1.2rem;
        text-align: left !important;
    }

    .fourthText {
        font-size: 0.9rem;
        font-family: 'Poppins-Regular';

    }

    .additionalText {
        font-size: 25px;
        text-align: left;
        margin-top: 1.5rem;
    }

    .eyesImage {

        width: 13%;

        position: relative;
        margin: 7% 20% 5% 0%;
        transform: rotate(7deg);

    }

    .boxTwo {
        overflow: hidden;
        position: absolute;
        width: 70%;
        height: 30%;
        top: 58%;
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        left: 62%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 2;
        border-radius: 10%;
    }

    .boxTwo img {
        position: relative;
        width: 115%;
        height: 101%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(0deg);
    }

    .boxOne img {
        position: relative;
        width: 120%;
        height: 155%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(-15deg);
    }

    .boxOne {
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        overflow: hidden;
        position: relative;
        width: 70%;
        height: 30%;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 1;
        border-radius: 10%;

    }

    .iconoUserMap {
        width: 40%;
        z-index: 3;
        position: absolute;
        bottom: 68%;
        left: 62%;

    }

    .iconoGirl {
        width: 40%;
        z-index: 3;
        position: absolute;
        top: 35%;
        left: 2%;

    }


    .letterParagraph {
        margin-top: 60px;
        user-select: none;
        font-family: 'Poppins-Regular';
        font-size: 20px;


    }

    .fourthText {
        font-family: 'Poppins-Regular';
        font-size: 20px;
        color: #028470;

    }

}

@media (min-width: 1822px) and (max-width: 1909px) {



    .containerTextMoreImage {
        margin-top: -25px;
    }

    .defineContainerText {

        width: 100%;

    }

    .leftContainer {
        align-items: flex-start;
        margin-top: 0rem;

    }

    .firstTextLeftContainer,
    .firstTextLeftContainerEnglish {
        line-height: 4rem;
        font-size: 2.35rem;
        font-family: 'Poppins-Regular';
        font-weight: bold;

    }

    .firstTextLeftContainerEnglish {
        font-size: 2.0rem
    }


    .contenedorLetterBlueContainer {
        margin-top: -1rem;


    }

    .thirdText {
        font-family: 'Poppins-Regular';
        font-weight: bold;
        font-size: 2rem;
        line-height: 0px;
        background-color: #00FFD7;
    }

    .thirdTextEnglish {
        font-size: 1.7rem;
    }

    .secondTextLeftContainer,
    .secondTextLeftContainerEnglish {
        font-size: 2.35rem;
        font-family: 'Poppins-Regular';
        font-weight: bold;
        line-height: 1rem;

    }



    .secondTextLeftContainerEnglish {
        font-size: 2.0rem
    }

    .leftContainer {

        width: 50%;
        height: 95%;

    }

    .textH3 {
        font-family: 'Poppins-Regular';

        font-size: 2.35rem;
        line-height: 0rem;
    }

    .letterParagraph {
        font-size: 1.2rem;
        text-align: left;
        margin-top: 10px;
        margin-left: 20px;
    }

    .fourthText {
        font-size: 0.9rem;
        font-family: 'Poppins-Regular';

    }

    .additionalText {
        font-size: 25px;
        margin-left: 20px;
        text-align: left;
        margin-top: 1.5rem;
    }

    .eyesImage {

        width: 13%;

        position: relative;
        margin: 8% 28% 5% 0%;
        transform: rotate(7deg);

    }

    .boxTwo {
        overflow: hidden;
        position: absolute;
        width: 70%;
        height: 30%;
        top: 58%;
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        left: 62%;
        transform: translate(-50%, -50%);
        background-color: transparent;
        z-index: 2;
        border-radius: 10%;
    }

    .boxTwo img {
        position: relative;
        width: 115%;
        height: 101%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(0deg);
    }

    .boxOne img {
        position: relative;
        width: 120%;
        height: 155%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(-15deg);
    }

    .boxOne {
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        overflow: hidden;
        position: relative;
        width: 70%;
        height: 30%;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: transparent;
        z-index: 1;
        border-radius: 10%;

    }

    .iconoUserMap {
        width: 40%;
        z-index: 3;
        position: absolute;
        bottom: 68%;
        left: 62%;

    }

    .iconoGirl {
        width: 40%;
        z-index: 3;
        position: absolute;
        top: 35%;
        left: 2%;

    }


    .letterParagraph {
        user-select: none;
        font-family: 'Poppins-Regular';
        font-size: 20px;

    }

    .containerLetterParagraph {
        
        margin-left: 0;
        padding-left: 0;
        margin-right: 0;
        text-align: left;
    }

    .fourthText {
        font-family: 'Poppins-Regular';
        font-size: 20px;
        color: #028470;

    }
}

@media (min-width: 1910px) and (max-height: 749px) and (min-height: 560px) {
    .firstContainer {
        padding-top: 5vh;
    }



    .containerBlueLetter {
        width: 40%;
    }

    .boxOne {
        width: 35vw;
        height: calc(35vw * 0.76);
    }

    .iconoGirl {
        width: 20vw;
        height: 20vw;
    }

    .iconoUserMap {
        width: 20vw;
        height: 20vw;
        top: -7%;
    }

    .boxTwo {
        width: 35vw;
        height: calc(35vw * 0.65);
        top: 60%;
    }

    .defineContainerText {
        margin-top: 10%;
    }
}


@media (min-width: 1910px) and (max-height: 1000px) and (min-height: 750px) {
    .containerLetterParagraph {
        width: 85%;
    }

    .containerAdditionalText {
        width: 80%;
    }

    .containerTextMoreImage {
        margin-top: -10px;
    }

    .defineContainerText {

        width: 100%;

    }

    .leftContainer {

        margin-top: 0rem;

    }

    .firstTextLeftContainer,
    .firstTextLeftContainerEnglish {
        line-height: 6rem;
        font-size: 40px;
        font-family: 'Poppins-Regular';
        font-weight: bold;

    }

    .firstTextLeftContainerEnglish {
        font-size: 2.0rem
    }

    .contenedorLetterBlueContainer {
        margin-top: -0.9rem;

    }

    .thirdText,
    .thirdTextEnglish {
        font-family: 'Poppins-Regular';
        font-weight: bold;
        font-size: 40px;
        background-color: #00FFD7;
    }



    .secondTextLeftContainer,
    .secondTextLeftContainerEnglish {
        font-size: 40px;
        font-family: 'Poppins-Regular';
        font-weight: bold;
        line-height: 1rem;
 

    }



    .secondTextLeftContainerEnglish {
        font-size: 40px;
    }

    .leftContainer {

        width: 50%;
        height: 95%;

    }

    .textH3 {
        font-family: 'Poppins-Regular';

        font-size: 30px;
        line-height: 0rem;
        margin-top: 25px;
    }

    .letterParagraph {
        margin-top: 0px;
        font-size: 20px;
        text-align: left;
        margin-left: 10px;
    }

    .fourthText {
        font-size: 0.9rem;
        font-family: 'Poppins-Regular';

    }

    .additionalText {
        font-size: 25px;
        text-align: left;
        margin-left: 10px;
        margin-top: 1.5rem;
    }

    .eyesImage {

        width: 13%;

        position: relative;
        margin: 8% 28% 5% 0%;
        transform: rotate(7deg);

    }

    .boxTwo {
        overflow: hidden;
        position: absolute;
        width: 60%;
        height: 30%;
        top: 58%;
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        left: 62%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 2;
        border-radius: 10%;
    }

    .boxTwo img {
        position: relative;
        width: 115%;
        height: 101%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(0deg);
    }

    .boxOne img {
        position: relative;
        width: 120%;
        height: 155%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(-15deg);
    }

    .boxOne {
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        overflow: hidden;
        position: relative;
        width: 60%;
        height: 30%;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 1;
        border-radius: 10%;

    }

    .iconoUserMap {
        width: 30%;
        z-index: 3;
        position: absolute;
        bottom: 68%;
        left: 65%;

    }

    .iconoGirl {
        width: 30%;
        z-index: 3;
        position: absolute;
        top: 35%;
        left: 15%;

    }


    .letterParagraph {
        user-select: none;
        font-family: 'Poppins-Regular';
        font-size: 20px;


    }

    .fourthText {
        font-family: 'Poppins-Regular';
        font-size: 20px;
        color: #028470;

    }

}

@media (min-width: 1821px) and (max-width: 2100px) and (min-height: 1001px) and (max-height: 1400px) {
    .letterParagraph {
        margin-top: 80px;
    }
}

@media (min-width: 1910px) and (max-height: 1300px) and (min-height: 1001px) {
    .containerLetterParagraph {
        margin-top: 9vh;
        width: 85%;

    }
    .containerTextMoreImage {
        margin-top: -10px;
    }

    .defineContainerText {

        width: 100%;

    }

    .leftContainer {

        margin-top: 1rem;
        padding-top: 50px;

    }

    .firstTextLeftContainer,
    .firstTextLeftContainerEnglish {
        line-height: 2rem;
        font-size: 2.35rem;
        font-family: 'Poppins-Regular';
        font-weight: bold;

    }

    .firstTextLeftContainerEnglish {
        font-size: 2.0rem
    }

    .containerBlueLetter {
        display: flex;
        align-items: center;
        margin-top: 0.4rem;

        height: 2rem;

    }

    .contenedorLetterBlueContainer {
        margin-top: -0.9rem;

    }

    .thirdText,
    .thirdTextEnglish {
        font-family: 'Poppins-Regular';
        font-weight: bold;
        font-size: 2rem;
        background-color: #00FFD7;
    }



    .secondTextLeftContainer,
    .secondTextLeftContainerEnglish {
        font-size: 2.35rem;
        font-family: 'Poppins-Regular';
        font-weight: bold;
        line-height: 1rem;
        margin-bottom: 5px;

    }



    .secondTextLeftContainerEnglish {
        font-size: 2.0rem
    }

    .leftContainer {

        width: 50%;
        height: 95%;

    }

    .textH3 {
        font-family: 'Poppins-Regular';

        font-size: 2.35rem;
        line-height: 0rem;
        margin-top: 25px;
    }

    .letterParagraph {
        font-size: 1.2rem;
        text-align: left;
        margin-top: 80px;
    }

    .fourthText {
        font-size: 0.9rem;
        font-family: 'Poppins-Regular';

    }

    .additionalText {
        font-size: 35px;
        text-align: left;
        margin-top: 1.5rem;
    }

    .eyesImage {

        width: 13%;

        position: relative;
        margin: 8% 13% 5% 0%;
        transform: rotate(7deg);

    }

    .boxTwo {
        overflow: hidden;
        position: absolute;
        width: 70%;
        height: 30%;
        top: 58%;
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        left: 62%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 2;
        border-radius: 10%;
    }

    .boxTwo img {
        position: relative;
        width: 115%;
        height: 101%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(0deg);
    }

    .boxOne img {
        position: relative;
        width: 120%;
        height: 155%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(-15deg);
    }

    .boxOne {
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        overflow: hidden;
        position: relative;
        width: 70%;
        height: 30%;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 1;
        border-radius: 10%;

    }

    .iconoUserMap {
        width: 50%;
        z-index: 3;
        position: absolute;
        bottom: 68%;
        left: 62%;

    }

    .iconoGirl {
        width: 50%;
        z-index: 3;
        position: absolute;
        top: 35%;
        left: 2%;

    }


    .letterParagraph {
        user-select: none;
        font-family: 'Poppins-Regular';
        font-size: 35px;


    }

    .fourthText {
        font-family: 'Poppins-Regular';
        font-size: 35px;
        color: #028470;

    }
}


@media only screen and (min-width:2400px) and (min-height:1600px) {



    .containerLetterParagraph {
        margin-top: 5vh;
        width: 85%;

    }

    .containerTextMoreImage {
        margin-top: -10px;
    }

    .defineContainerText {

        width: 100%;

    }

    .leftContainer {

        margin-top: 30rem;
        padding-top: 250px;

    }

    .firstTextLeftContainer,
    .firstTextLeftContainerEnglish {
        line-height: 7rem;
        font-size: 2.35vw;
        font-family: 'Poppins-Regular';
        font-weight: bold;

    }


    .containerBlueLetter {
        display: flex;
        align-items: center;
        margin-top: 0.4rem;

        height: 2rem;

    }

    .contenedorLetterBlueContainer {
        margin-top: -0.9rem;

    }

    .thirdText,
    .thirdTextEnglish {
        font-family: 'Poppins-Regular';
        font-weight: bold;
        font-size: 2.35vw;
        background-color: #00FFD7;
        border-radius: 25px;
    }



    .secondTextLeftContainer,
    .secondTextLeftContainerEnglish {
        font-size: 2.35vw;
        font-family: 'Poppins-Regular';
        font-weight: bold;
        line-height: 1rem;
        margin-bottom: 5px;

    }



    .secondTextLeftContainerEnglish {
        font-size: 2.0rem
    }

    .leftContainer {

        width: 50%;
        height: 95%;

    }

    .textH3 {
        font-family: 'Poppins-Regular';

        font-size: 2.35vw;
        line-height: 0rem;
        margin-top: 50px;
    }

    .letterParagraph {
        font-size: 2.35vw;
        text-align: left;
    }

    .fourthText {
        font-size: 2.35vw;
        font-family: 'Poppins-Regular';

    }

    .additionalText {
        font-size: 2.15vw;
        text-align: left;
        margin-top: 1.5rem;
    }

    .eyesImage {

        width: 13%;

        position: relative;
        margin: 8% 13% 5% 0%;
        transform: rotate(7deg);

    }

    .boxTwo {
        overflow: hidden;
        position: absolute;
        width: 70%;
        height: 30%;
        top: 58%;
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        left: 62%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 2;
        border-radius: 10%;
    }

    .boxTwo img {
        position: relative;
        width: 115%;
        height: 101%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(0deg);
    }

    .boxOne img {
        position: relative;
        width: 120%;
        height: 155%;
        top: 50%;
        left: 50%;
        /* Asegura que la imagen cubra todo el div */
        transform: translate(-50%, -50%) rotate(-15deg);
    }

    .boxOne {
        box-shadow: 5px 5px 1px 0px rgb(0, 0, 0, 0.3);
        overflow: hidden;
        position: relative;
        width: 70%;
        height: 30%;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        z-index: 1;
        border-radius: 10%;

    }

    .iconoUserMap {
        width: 50%;
        z-index: 3;
        position: absolute;
        bottom: 68%;
        left: 62%;

    }

    .iconoGirl {
        width: 50%;
        z-index: 3;
        position: absolute;
        top: 35%;
        left: 2%;

    }






}



@media screen and (min-width:2600px) {

    .firstContainer {
        margin-bottom: -50vh;
    }

    .boxOne {
        width: 500px;
    }

    .containerBlueLetter {
        width: 350px;
        display: flex;


    }

    .containerTextMoreImage {
        margin-top: 8vh;
        margin-bottom: -6vh;
    }

    .contenedorLetterBlueContainer {
        display: flex;
        width: 70%;
        align-items: left;
        justify-content: left;
        margin-bottom: 4vh;
        margin-left: -0.5vh;
    }

    .containerLetterParagraph {
        width: 80%;
    }

    .boxTwo {
        width: 500px;
    }

    .iconoUserMap {
        left: 25vw;
        top: 3vh;
    }

    .iconoGirl {
        left: 9vw;
    }

    .letterParagraph {
        width: 100%;
    }

    .additionalText {
        margin-left: -3vh;
    }


}

@media screen and (min-width:3900px) {


    .iconoUserMap {
        left: 25vw;
        top: 18vh;
        width: 450px;
    }

    .iconoGirl {
        left: 17vw;
        top: 48vh;
        width: 450px;
    }
}


@media screen and (min-width:5000px) {
    .boxOne {
        width: 60%;
        height: 55vh;
    }

    .boxTwo {
        top: 95vh;
        width: 60%;
        height: 55vh;
    }

    .iconoUserMap {
        width: 50%;
        top: 1vh;
    }

    .iconoGirl {
        top: 65vh;
        left: 2vw;
        width: 50%;
    }

}