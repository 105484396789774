@font-face {
    font-family: 'Poppins Regular';
    src: url('../Bloque-1/Fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins Bold';
    src: url('../Bloque-1/Fonts/Poppins-Regular.ttf') format('truetype');
}

/* traigo rockbelt */
@font-face {
    font-family: 'RockSalt';
    src: url('../Bloque-1/Fonts/RockSalt-Regular.ttf') format('truetype');
}

h2 {
    font-family: 'Poppins Regular', sans-serif;
    font-size: 64px;
    font-weight: 400;
    line-height: 65px;
    text-align: left;
}

.sliderBloq3 {
    display: none;
}

.colorGreenTextBloq3 {
    font-weight: bold;
    color: #05a990;
}

b {
    font-family: 'Poppins Bold', sans-serif;
    font-size: 42.69px;
    font-weight: 700;
    line-height: 43.36px;
    text-align: left;
}

.textoBloque {
    font-family: 'Poppins Regular', sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
}

.placesContainerMobile {
    display: none;
}

.fondo3 {
    background: radial-gradient(84.06% 70.03% at 19.16% -2.84%, #060659 0%, #03033D 34.54%, #000000 63.01%, #000000 97.48%);
    height: auto;
    padding: 50vh 0 40vh 0;
    margin-bottom: 3%;
    background-image: url('../Assets/block-3-background.png');
    background-color: white;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
}

.subtituloPoppins {
    font-family: 'Poppins Regular', sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
    text-align: left;
    color: #ffffff;
}

.bloqueCabecera {
    width: 40vw;
    margin-left: 5vw;
    margin-bottom: 10vh;
    margin-top: 10vh;
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 1.5s ease-out, opacity 0.5s ease-out;
}

.bloqueCabecera.active {
    transform: translateX(0);
    opacity: 1;
}

.textoNormalPoppins,
.textoNormalPoppinsMobile {
    font-family: 'Poppins Regular', sans-serif;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    font-size: 18px;
    margin-top: 0;
}

.placesContainer {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.arrowbloque2 {
    width: 50px;
}

.arrowBox {
    position: absolute;
}

.arrowText3 {
    font-family: 'RockSalt', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
}


.arrow1 {
    position: absolute;
    top: -5%;
    right: 35%;
}

.arrowImage1 {
    transform: rotate(-90deg);
    margin-left: 0vw;
}

.arrowImage2 {
    transform: rotate(25deg);
    margin-left: 0vw;
}

.arrow2 {
    position: absolute;
    top: 15%;
    right: 72%;
}

.arrow3 {
    position: absolute;
    top: 45%;
    right: 76%;
}

.arrowImage3 {
    transform: rotate(25deg);
}

.places {
    width: 75vw;
}

.arrow4 {
    position: absolute;
    margin: 0;
    top: 92%;
    right: 49%;
    padding: 0;
}

.arrowImage4 {
    transform: rotate(-90deg);
    margin: 0;
    padding: 0;
}

.texto1Bloque3 {
    line-height: 45px;
}

.textoNormalPoppinsMobile {
    display: none;
}

.slider {
    display: none;
}

.ctnLokdis {

    display: none;
}


@media (max-width: 500px) {
    .ctnLokdisDesk {
        display: none;
    }
}

@media (min-width: 501px) {
    .fondo3 {
        position: relative;
    }

    .ctnLokdisDesk {
        left: 1%;
        top: 80%;
        position: absolute;
        transform: rotate(-15deg);
    }

    .ctnLokdisDesk img {
        width: 250px;
    }
}

@media only screen and (min-width:360px) and (min-height:740px) and (max-width:370px) and (max-height:800px) {
    .fondo3 {
        margin-top: -4vh;
        padding-bottom: 44vh;
        background-position: top;
        height: 100vh;
        background-color: transparent;
    }

    .bloqueCabecera {
        width: auto;
        margin: 0 20px;
        transform: none;
        transition: none;
        display: flex;
        flex-direction: column;
        position: relative;
        top: -45vh;
        margin-bottom: -40vh;
    }

    .subtituloPoppins {
        font-size: 1.3rem;
        line-height: 2rem;
        font-weight: 500;
    }

    .textoNormalPoppinsMobile {
        display: block;
        font-size: 1.0rem;
        line-height: 1.8rem;
    }

    .textoNormalPoppins {
        display: none;
    }

    .texto2Bloque3,
    .textoNormalPoppinsMobile {
        margin-top: 2rem;
    }

    .colorGreenTextBloq3 {
        font-weight: bold;
        color: #05a990;
    }

    .texto1Bloque3 {
        font-size: 1.1rem;
        line-height: 1.3rem;
        margin-bottom: 35px;
    }

    .fondoAzulBloque3 {
        color: #000000;
        background-color: var(--Color-acento-landing, #00FFD7);
        display: inline;
        padding: 0 8px;
        margin: 5px 0 20px 0;
        border-radius: 6px;
        font-weight: 600;
    }


    .placesContainer {
        display: none;
    }

    .placesContainerMobile {
        display: block;
        margin: 2.5rem 0;
    }

    .sliderBloq3 {
        margin-top: 0vh;
        display: inline-block;
        width: 85%;
        height: auto;
    }

}

@media only screen and (min-width:375px) and (max-width:430px) and (min-height:667px) and (max-height:700px) {

    .fondo3 {
        margin-top: -5vh;
        padding-bottom: 55vh;
        background-position: top;
        height: 110vh;
        background-color: transparent;

    }



    .bloqueCabecera {
        width: auto;
        margin: 0 20px;
        transform: none;
        transition: none;
        display: flex;
        flex-direction: column;
        position: relative;
        top: -40vh;
        margin-bottom: -37vh;
    }

    .subtituloPoppins {
        font-size: 1.3rem;
        line-height: 2rem;
        font-weight: 500;
    }

    .textoNormalPoppinsMobile {
        display: block;
        font-size: 1.0rem;
        line-height: 1.8rem;
    }

    .textoNormalPoppins {
        display: none;
    }

    .texto2Bloque3,
    .textoNormalPoppinsMobile {
        margin-top: 2rem;
    }

    .colorGreenTextBloq3 {
        font-weight: bold;
        color: #05a990;
    }

    .texto1Bloque3 {
        font-size: 1.1rem;
        line-height: 1.3rem;
        margin-bottom: 35px;
    }

    .fondoAzulBloque3 {
        color: #000000;
        background-color: var(--Color-acento-landing, #00FFD7);
        display: inline;
        padding: 0 8px;
        margin: 5px 0 20px 0;
        border-radius: 6px;
        font-weight: 600;
    }


    .placesContainer {
        display: none;
    }

    .placesContainerMobile {
        display: block;
        margin: 2.5rem 0;
    }

    .sliderBloq3 {
        margin-top: 0vh;
        display: inline-block;
        width: 85%;
        height: auto;
    }


}


@media only screen and (min-width:430px) and (min-height:932px) and (max-width:500px) and (max-height:1000px) {

    .fondo3 {
        margin-top: -5vh;
        padding-bottom: 55vh;
        background-position: top;
        height: 60vh;
        background-color: transparent;

    }

    .bloqueCabecera {
        width: auto;
        margin: 0 20px;
        transform: none;
        transition: none;
        display: flex;
        flex-direction: column;
        position: relative;
        top: -45vh;
        margin-bottom: -37vh;
    }

    .subtituloPoppins {
        font-size: 1.3rem;
        line-height: 2rem;
        font-weight: 500;
    }

    .textoNormalPoppinsMobile {
        display: block;
        font-size: 1.0rem;
        line-height: 1.8rem;
    }

    .textoNormalPoppins {
        display: none;
    }

    .texto2Bloque3,
    .textoNormalPoppinsMobile {
        margin-top: 2rem;
    }

    .colorGreenTextBloq3 {
        font-weight: bold;
        color: #05a990;
    }

    .texto1Bloque3 {
        font-size: 1.1rem;
        line-height: 1.3rem;
        margin-bottom: 35px;
    }

    .fondoAzulBloque3 {
        color: #000000;
        background-color: var(--Color-acento-landing, #00FFD7);
        display: inline;
        padding: 0 8px;
        margin: 5px 0 20px 0;
        border-radius: 6px;
        font-weight: 600;
    }


    .placesContainer {
        display: none;
    }

    .placesContainerMobile {
        display: block;
        margin: 2.5rem 0;
    }

    .sliderBloq3 {
        margin-top: 0vh;
        display: inline-block;
        width: 85%;
        height: auto;
    }


}


@media only screen and (max-width:500px) and (max-height:750px) {
    .fondo3 {
        margin-top: -4vh;
        padding-bottom: 300px;
        background-position: top;
        height: 110vh;
        background-color: transparent;
    }

    .ctnLokdis {
        display: none;

    }

    .ctnLokdis img {
        position: absolute;
        width: 110px;
        top: 1160px;
        left: 30px;
    }

    .bloqueCabecera {
        width: auto;
        margin: 0 20px;
        transform: none;
        transition: none;
        display: flex;
        flex-direction: column;
        position: relative;
        top: -43vh;
        margin-bottom: -30vh;
    }

    .subtituloPoppins {
        font-size: 1.3rem;
        line-height: 2rem;
        font-weight: 500;
    }

    .textoNormalPoppinsMobile {
        display: block;
        font-size: 1.0rem;
        line-height: 1.8rem;
    }

    .textoNormalPoppins {
        display: none;
    }

    .texto2Bloque3,
    .textoNormalPoppinsMobile {
        margin-top: 2rem;
    }

    .colorGreenTextBloq3 {
        font-weight: bold;
        color: #05a990;
    }

    .texto1Bloque3 {
        font-size: 1.1rem;
        line-height: 1.3rem;
        margin-bottom: 35px;
    }


    .fondoAzulBloque3 {
        color: #000000;
        background-color: var(--Color-acento-landing, #00FFD7);
        display: inline;
        padding: 0 8px;
        margin: 5px 0 20px 0;
        border-radius: 6px;
        font-weight: 600;
    }


    .placesContainer {
        display: none;
    }

    .placesContainerMobile {
        display: block;
        margin: 2.5rem 0;
    }

    .sliderBloq3 {
        margin-top: -105vh;
        display: inline-block;
        width: 85%;
        height: auto;
    }


}

@media only screen and (max-width:500px) and (min-height:750px) and (max-height:1000px) {

    .fondo3 {
        margin-top: -4vh;
        padding-bottom: 375px;
        background-position: top;
        height: 80vh;
        background-color: transparent;
    }

    .bloqueCabecera {
        width: auto;
        margin: 0 20px;
        transform: none;
        transition: none;
        display: flex;
        flex-direction: column;
        position: relative;
        top: -43vh;
        margin-bottom: -30vh;
    }

    .subtituloPoppins {
        font-size: 1.3rem;
        line-height: 2rem;
        font-weight: 500;
    }

    .textoNormalPoppinsMobile {
        display: block;
        font-size: 1.0rem;
        line-height: 1.8rem;
    }

    .textoNormalPoppins {
        display: none;
    }

    .texto2Bloque3,
    .textoNormalPoppinsMobile {
        margin-top: 2rem;
    }

    .colorGreenTextBloq3 {
        font-weight: bold;
        color: #05a990;
    }

    .texto1Bloque3 {
        font-size: 1.1rem;
        line-height: 1.3rem;
        margin-bottom: 35px;
    }

    .fondoAzulBloque3 {
        color: #000000;
        background-color: var(--Color-acento-landing, #00FFD7);
        display: inline;
        padding: 0 8px;
        margin: 5px 0 20px 0;
        border-radius: 6px;
        font-weight: 600;
    }


    .placesContainer {
        display: none;
    }

    .placesContainerMobile {
        display: block;
        margin: 2.5rem 0;
    }

    .sliderBloq3 {
        margin-top: -105vh;
        display: inline-block;
        width: 85%;
        height: auto;
    }


}

@media (max-width: 700px) {
    .arrowText3 {
        font-size: 8px;
    }

    .arrow1 {
        top: -10%;
        right: 35%;
    }

    .arrow2 {
        top: 5%;
        right: 75%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 90%;
        right: 50%;
    }

    .arrowImage4 {
        transform: rotate(-110deg);
        margin: 0;
        padding: 0;
    }

    .bloqueCabecera {
        margin-top: 20vh;
    }
}

@media (max-width: 900px) and (min-width: 701px) {
    .arrowText3 {
        font-size: 8px;
    }




    /* .arrow3 {
        top: 35vh;
        right: 52vw;
    }

    .arrow4 {
        top: 75.5vh;
        right: 33vw;
    } */


    .arrowImage4 {
        transform: rotate(-110deg);
        margin: 0;
        padding: 0;
    }

    .arrowImage4 {
        margin: 0;
        padding: 0;
    }

    .bloqueCabecera {
        margin-top: 20vh;
    }
}

@media (max-width: 900px) and (max-height: 415px) and (min-width: 701px) {
    .arrowText3 {
        font-size: 8px;
    }

    /* 

    .arrow3 {
        top: 55vh;
        right: 52vw;
    }

    .arrow4 {
        top: 115.5vh;
        right: 33vw;
    } */

    .arrow1 {
        top: -10%;
        right: 35%;
    }

    .arrow2 {
        top: 5%;
        right: 75%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 90%;
        right: 50%;
    }

    .arrowImage4 {
        transform: rotate(-110deg);
        margin: 0;
        padding: 0;
    }

    .bloqueCabecera {
        margin-top: 20vh;
    }
}

/* Resoluciones medianas */
@media (min-width: 901px) and (max-width: 1100px) {
    .arrowText3 {
        font-size: 12px;
    }

    .bloqueCabecera {
        margin-top: 10vh;
    }

    .arrow1 {
        top: -10%;
        right: 35%;
    }

    .arrow2 {
        top: 5%;
        right: 75%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 90%;
        right: 50%;
    }
}

/* @media (min-width: 1350px) and (min-height: 850px) {
    .fondo3 {
        padding-bottom: 30vh;
    }
} */

@media (min-width: 1004px) and (min-height: 748px) and (max-width: 1150px) {
    .arrow1 {
        top: -10%;
        right: 35%;
    }

    .arrow2 {
        top: 5%;
        right: 75%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 90%;
        right: 46%;
    }

    .arrowImage4 {
        transform: rotate(-110deg);
        margin: 0;
        padding: 0;
    }
}

@media (min-width: 1151px) and (min-height: 748px) and (max-width: 1345px) {
    .arrow1 {
        top: -10%;
        right: 35%;
    }

    .arrow2 {
        top: 5%;
        right: 75%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 90%;
        right: 46%;
    }


    .arrowImage4 {
        transform: rotate(-110deg);
        margin: 0;
        padding: 0;
    }
}

@media (min-width: 1346px) and (min-height: 748px) and (max-width: 1419px) {
    .arrow1 {
        top: -10%;
        right: 35%;
    }

    .arrow2 {
        top: 5%;
        right: 75%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 90%;
        right: 50%;
    }

}

@media (min-width: 1420px) and (min-height: 880px) and (max-width: 1525px) {
    .arrow1 {
        top: -10%;
        right: 35%;
    }

    .arrow2 {
        top: 5%;
        right: 75%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 90%;
        right: 50%;
    }
}


@media (min-width: 1506px) and (max-width: 1606px) and (min-height: 701px) and (max-height: 750px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 5%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 92%;
        right: 48%;
    }
}

@media (min-width: 1506px) and (max-width: 1606px) and (min-height: 751px) and (max-height: 800px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 5%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 92%;
        right: 48%;
    }
}

@media (min-width: 1506px) and (max-width: 1606px) and (min-height: 801px) and (max-height: 850px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 5%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 92%;
        right: 48%;
    }
}

@media (min-width: 1506px) and (max-width: 1606px) and (min-height: 851px) and (max-height: 900px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 5%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 92%;
        right: 48%;
    }
}

@media (min-width: 1526px) and (min-height: 901px) and (max-width: 1599px) {

    @media (min-width: 1526px) and (min-height: 844px) {

        .fondo3 {
            padding-bottom: 30vh;
        }

        .arrow4 {
            top: 152.5vh;
            right: 53vw;
        }
    }

}


@media (min-width: 1600px) and (max-height: 880px) {

    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 5%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 92%;
        right: 48%;
    }
}

@media (min-width: 1910px) and (max-height: 800px) and (min-height: 560px) {
    .fondo3 {
        padding-top: 75vh;
    }

    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 5%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 92%;
        right: 48%;
    }
}


@media (min-width: 1910px) and (max-height: 609px) and (min-height: 560px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 92%;
        right: 48%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}

@media (min-width: 1910px) and (max-height: 649px) and (min-height: 610px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 92%;
        right: 48%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}

@media (min-width: 1910px) and (max-height: 699px) and (min-height: 650px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 92%;
        right: 48%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}

@media (min-width: 1910px) and (max-height: 749px) and (min-height: 700px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 92%;
        right: 48%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}

@media (min-width: 1910px) and (max-height: 799px) and (min-height: 750px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 75%;
    }

    .arrow4 {
        top: 92%;
        right: 48%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}

@media (min-width: 1910px) and (max-height: 849px) and (min-height: 800px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 76%;
    }

    .arrow4 {
        top: 92%;
        right: 49%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}

@media (min-width: 1910px) and (max-height: 899px) and (min-height: 850px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 76%;
    }

    .arrow4 {
        top: 92%;
        right: 49%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}

@media (min-width: 1910px) and (max-height: 949px) and (min-height: 900px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 76%;
    }

    .arrow4 {
        top: 92%;
        right: 49%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}

@media (min-width: 1910px) and (max-height: 999px) and (min-height: 950px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 76%;
    }

    .arrow4 {
        top: 92%;
        right: 49%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}

@media (min-width: 1910px) and (max-height: 1049px) and (min-height: 1000px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 76%;
    }

    .arrow4 {
        top: 92%;
        right: 49%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}

@media (min-width: 1910px) and (max-height: 1099px) and (min-height: 1050px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 76%;
    }

    .arrow4 {
        top: 92%;
        right: 49%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}

@media (min-width: 1910px) and (max-height: 1149px) and (min-height: 1100px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 76%;
    }

    .arrow4 {
        top: 92%;
        right: 49%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}

@media (min-width: 1910px) and (max-height: 1199px) and (min-height: 1150px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 76%;
    }

    .arrow4 {
        top: 92%;
        right: 49%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}

@media (min-width: 1910px) and (max-height: 1249px) and (min-height: 1200px) {

    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 76%;
    }

    .arrow4 {
        top: 92%;
        right: 49%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}

@media (min-width: 1910px) and (max-height: 1300px) and (min-height: 1250px) {
    .arrow1 {
        top: -5%;
        right: 35%;
    }

    .arrow2 {
        top: 15%;
        right: 72%;
    }

    .arrow3 {
        top: 45%;
        right: 76%;
    }

    .arrow4 {
        top: 92%;
        right: 49%;
    }

    .fondo3 {
        padding-bottom: 400px
    }
}