@import url('../../fonts/font.css');

.container {
    z-index: 2;
    background-image: url('../../Assets/block-5-background.png');
    width: 100%;
    min-height: 120vh;
    /* Asegura que el contenedor ocupe al menos toda la pantalla */
    /* Degradado de #242244 a negro */
    user-select: none;
    overflow: hidden;
    background-color: white;
    position: relative;
    align-items: center;
    display: flex;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.carrousel {
    width: 100%;
    position: relative;
    top: 35.5vh;
    z-index: 1;
}

.blockFiveContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: -30vh;
}

.containerLetter {
    padding: 5%;
    color: white;
    text-align: left;
    width: 75%;
    margin-top: 15%;
}

.h2Letter {
    font-family: 'PlusJakartaSans-Bold';
    font-size: 30px;
}

.paragraph {
    font-family: 'PlusJakartaSans-Regular';
    font-size: 23px;

}

.paragraphSmall {
    font-family: 'Poppins-Regular';
    font-size: 18px;
}

.resaltadoDesktop {
    background-color: #00FFD7;
    border-radius: 15px;
    color: black;
    font-weight: bold;
    padding: 0 4px;
}

.buttonCallToAction {
    margin-left: 0%;
    width: 63%;
    padding: 1%;
    font-size: 24px;
    color: #060661;
    font-family: 'Roboto-Bold';
    font-weight: bold;
    border-radius: 15px;
    background-color: #ffffff;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.buttonCallToAction:hover {
    background-color: #b0fcff;
    transform: scale(1.05);
}

.containerLogo {
    position: absolute;
    top: 9%;
    left: 65%;
    transform: translateX(-30%);
    width: 25%;
}

.containerLogo img {
    width: 300px;
    margin-top: 10%;
}

.carrousel {
    width: 100%;
    position: relative;
    top: 33.5vh;
    z-index: 1;
}



@media only screen and (min-width:430px) and (min-height:932px) and (max-width:500px) and (max-height:1000px) {

    .carrousel {
        width: 100%;
        position: relative;
        top: 22.5vh;
        z-index: 1;
    }



    .container {
        z-index: 2;
        background-image: url('../../Assets/fondo-block-five-movil-design.png');
        width: 100%;
        min-height: 120vh;
        /* Asegura que el contenedor ocupe al menos toda la pantalla */
        /* Degradado de #242244 a negro */
        user-select: none;
        overflow: hidden;
        background-color: transparent;
        position: relative;
        align-items: center;
        display: flex;
        z-index: 0;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: -45vh;

    }

    .containerLetter {
        margin-top: -45vh;
        width: 280px;

    }

    .containerLogo {
        display: flex;
    }

    .h2Letter {
        font-size: 16px;
    }

    .h2LetterEnglish {
        font-size: 16px;
    }

    .paragraph {
        font-size: 16px;
        color: black;
        font-family: 'Poppins-Bold';
    }

    .colorCyanBackground {
        font-size: 13px;
        background-color: #00FFD7;
        /* width: 58vw; */
        margin-top: 5vh;
        border-radius: 15px;
        padding: 5px;

    }

    .colorCyanBackgroundTwo {

        background-color: #00FFD7;
        /* width: 58vw; */
        line-height: 4rem;
        border-radius: 15px;
        padding: 5px;
        font-size: 13px;

    }




    .paragraphSmall {
        font-size: 16px;
    }

    .buttonCallToAction {
        width: 87vw;
        padding: 5%;
        font-size: 20px;
        color: #060661;
        font-family: 'Roboto-Bold';
        font-weight: bold;
        border-radius: 15px;
        background-color: #ffffff;
        transition: background-color 0.3s ease, transform 0.3s ease;
    }

    .buttonCallToAction:hover {
        background-color: #b0fcff;
        transform: scale(1.05);
    }
}


@media only screen and (max-width:500px) {

    
.containerLogo img {
    width: 100px;
    margin-top: 0%;
}


    .carrousel {
        width: 100%;
        position: relative;
        top: 20.5vh;
        z-index: 1;
    }



    .container {
        z-index: 2;
        background-image: url('../../Assets/fondo-block-five-movil-design.png');
        width: 100%;
        min-height: 120vh;
        /* Asegura que el contenedor ocupe al menos toda la pantalla */
        /* Degradado de #242244 a negro */
        user-select: none;
        overflow: hidden;
        background-color: transparent;
        position: relative;
        align-items: center;
        display: flex;
        z-index: 0;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: -45vh;

    }

    .containerLetter {
        margin-top: -35vh;
        width: 280px;

    }

    .containerLogo {
        display: flex;
        margin-left: calc(15vh * 0.30);
    }

    .h2Letter {
        font-size: 16px;
        line-height: 15px;
    }

    .h2LetterEnglish {
        font-size: 16px;
    }

    .paragraph {
        font-size: 16px;
        color: black;
        font-family: 'Poppins-Bold';
    }

    .colorCyanBackground {
        font-size: 13px;
        background-color: #00FFD7;
        /* width: 58vw; */
        margin-top: 2vh;
        border-radius: 15px;
        padding: 3px;
        line-height: 55px;

    }

    .colorCyanBackgroundTwo {
        font-size: 13px;
        background-color: #00FFD7;
        /* width: 58vw; */
        line-height: 0rem;
        border-radius: 15px;
        padding: 3px;
        color: black;


    }




    .paragraphSmall {
        font-size: 16px;
    }

    .buttonCallToAction {
        width: 87vw;
        padding: 5%;
        font-size: 20px;
        color: #060661;
        font-family: 'Roboto-Bold';
        font-weight: bold;
        border-radius: 15px;
        background-color: #ffffff;
        transition: background-color 0.3s ease, transform 0.3s ease;
    }

    .buttonCallToAction:hover {
        background-color: #b0fcff;
        transform: scale(1.05);
    }


}

@media only screen and (max-width:500px) and (max-height:800px) {

    .carrousel {
        width: 100%;
        position: relative;
        top: 22.5vh;
        z-index: 1;
    }

    .container {
        z-index: 2;
        background-image: url('../../Assets/fondo-block-five-movil-design.png');
        width: 100%;
        min-height: 120vh;
        /* Asegura que el contenedor ocupe al menos toda la pantalla */
        /* Degradado de #242244 a negro */
        user-select: none;
        overflow: hidden;
        background-color: transparent;
        position: relative;
        align-items: center;
        display: flex;
        z-index: 0;
        background-repeat: no-repeat;
        background-size: cover;
        margin-bottom: -35vh;

    }

    .containerLetter {
        margin-top: -15vh;
        width: 280px;

    }

    .containerLogo {
        display: flex;
    }

    .h2Letter {
        font-size: 16px;
    }

    .h2LetterEnglish {
        font-size: 16px;
    }

    .paragraph {
        font-size: 16px;
        color: black;
        font-family: 'Poppins-Bold';
    }

    .colorCyanBackground {
        font-size: 13px;
        background-color: #00FFD7;
        /* width: 58vw; */
        margin-top: 1vh;
        border-radius: 10px;
        padding: 5px;

    }

    .colorCyanBackgroundTwo {
        font-size: 13px;
        background-color: #00FFD7;
        /* width: 58vw; */
        border-radius: 10px;
        padding: 5px;

    }




    .paragraphSmall {
        font-size: 16px;
    }

    .buttonCallToAction {
        width: 87vw;
        padding: 5%;
        font-size: 20px;
        color: #060661;
        font-family: 'Roboto-Bold';
        font-weight: bolder;
        border-radius: 15px;
        background-color: #ffffff;
        transition: background-color 0.3s ease, transform 0.3s ease;
    }

    .buttonCallToAction:hover {
        background-color: #b0fcff;
        transform: scale(1.05);
    }
}

@media (min-width: 500px) and (max-width:600px) {
    .carrousel {
        width: 100%;
        position: relative;
        top: 70vh;
        z-index: 1;
    }
}

@media (min-width: 600px) and (max-width:700px) {
    .carrousel {
        width: 100%;
        position: relative;
        top: 60vh;
        z-index: 1;
    }
}

@media (min-width: 700px) and (max-width:1100px) {
    .carrousel {
        width: 100%;
        position: relative;
        top: 48vh;
        z-index: 1;
    }
}

@media (min-width: 700px) and (min-height:813.75px) {
    .carrousel {
        width: 100%;
        position: relative;
        top: 27.5vh;
        z-index: 1;
    }

}

@media only screen and (min-width:750px) and (min-height:390px) {

    .carrousel {
        top: 45vh;
    }


}

@media only screen and (min-width:750px) and (min-height:651px) {

    .carrousel {
        top: 30vh;
    }

}

@media only screen and (min-width:800px) and (min-height:360px) {
    .carrousel {
        top: 40vh;
    }
}

@media only screen and (min-width:900px) and (min-height:1156px) {

    .carrousel {
        top: 24vh;
    }

}

@media only screen and (min-width:800px) and (min-height:1236px) {
    .carrousel {
        width: 100%;
        position: relative;
        top: 23.5vh;
        z-index: 1;
    }


}

@media only screen and (min-width:1000px) and (min-height:1000px) {

    .carrousel {
        width: 100%;
        position: relative;
        top: 23.5vh;
        z-index: 1;
    }



}

@media (min-width: 1004px) and (min-height: 748px) {
    .carrousel {
        top: 25vh;
    }

    .container {
        min-height: 95vh;
    }
}

@media only screen and (min-width:1050px) and (max-height:700px) {
    .carrousel {
        width: 100%;
        position: relative;
        top: 34.5vh;
        z-index: 1;
    }

}

@media only screen and (min-width:1150px) and (max-height:700px) {
    .carrousel {
        width: 100%;
        position: relative;
        top: 34.5vh;
        z-index: 1;
    }

}

@media (min-width: 1300px) and (min-height:600px) {

    .carrousel {
        width: 100%;
        position: relative;
        top: 35.5vh;
        z-index: 1;
    }

}

@media (min-width: 1300px) and (min-height:766px) {

    .carrousel {
        width: 100%;
        position: relative;
        top: 31.5vh;
        z-index: 1;
    }

}

@media (min-width: 1350px) and (min-height:651px) {

    .carrousel {
        top: 36vh;
    }

    .containerLogo {

        left: 75vw;
        top: 5vh;
    }

    .buttonCallToAction {
        position: relative;
        top: 25px !important;
    }

}

@media (min-width: 1350px) and (min-height: 850px) {
    .carrousel {
        top: 25.5vh;
    }

    .containerLetter {
        margin-top: 10vh;
    }

    .container {
        min-height: 90vh;
    }
}

@media(min-width:1419px) and (min-height:976px) {

    .carrousel {
        width: 100%;
        position: relative;
        top: 24.5vh;
        z-index: 1;
    }

}



@media (min-width: 1501px) and (min-height: 901px) {
    .carrousel {
        top: 28vh;
    }



}

@media (min-width: 1506px) and (max-width: 1606px) and (min-height: 700px) and (max-height: 800px) {
    .carrousel {
        top: 35vh;
    }
}

@media only screen and (min-width:1680px) and (min-height:1050px) {
    .carrousel {
        z-index: 1;
        top: 25vh;
        position: relative;
    }
}


@media (min-width: 1900px) and (min-height:1000px) {

    .carrousel {
        top: 26vh;
    }

}

@media (min-width: 1910px) and (max-height: 650px) and (min-height: 560px) {
    .carrousel {
        top: 43vh;
    }
}

@media (min-width: 1910px) and (max-height: 750px) and (min-height: 651px) {
    .carrousel {
        top: 39vh;
    }
}

@media (min-width: 1910px) and (max-height: 950px) and (min-height: 751px) {
    .carrousel {
        top: 32vh;
    }

    .buttonCallToAction {
        top: 5vh !important;
    }

    .containerLetter {
        margin-top: 255px;
    }
}

@media (min-width: 1910px) and (max-height: 1199px) and (min-height: 1100px) {
    .carrousel {
        top: 24vh;
    }
}

@media (min-width: 1910px) and (max-height: 1300px) and (min-height: 1200px) {
    .carrousel {
        top: 20vh;
    }
}

@media screen and (min-width:2000px) {

    .carrousel {
        top: 18vh;
    }

}

@media only screen and (min-width:2400px) and (min-height:1600px) {


    .carrousel {
        top: 18vh;
    }


}

@media screen and (min-width:2600px) and (min-height:1300px) {

    .carrousel {
        top: 23vh;
    }
}

@media screen and (min-width:3900px) {

    .carrousel {
        top: 20vh !important;
    }

}