@import url('../../fonts/font.css');

@media only screen and (min-width:360px) and (min-height:640px) and (max-width:374px) and (max-height:666px) {
    .container {
        display: none;
    }

    .arrowDown {
        display: none;
    }

    .arrowDownSecond {
        display: none;
    }

    .arrowDownThree {
        display: none;
    }

    .containerParagraph {
        display: none;
    }

    .containerParagraphTwo {
        display: none;
    }

    .containerParagraphThree {
        display: none;
    }

    .containerBannerPhotoMovil {

        display: flex;
        background-color: transparent;
        width: 100%;
        height: 28vh;
        align-items: center;
        justify-content: center;
    }

    .containerPhotos {
        display: none;
    }

    .fatherContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 55vh;
        padding-bottom: 15vh;
    }

    .containerTextTwoForMoreText {
        width: 64%;
    }

    .containerTextTwo {
        height: 3vh;
        width: 179px;
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 1vw;
        align-items: center;
    }

    .containerTextThreee {
        height: 3vh;
        /* width: 189px; */
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 1vw;
        padding: 5px;

        align-items: center;
    }



    .letterContainer {
        margin-top: -55vh;
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: left;
    }

    .textTwoContainer {
        font-size: 15px;
        font-family: 'Poppins-Bold';
    }

    .textOneContainer {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.6rem;
        margin-bottom: -1vh;
    }

    .textThree {
        font-size: 16px;
        margin-bottom: -4vh;
    }

    .textNew {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-family: 'Poppins-Bold';
        line-height: 10vh;
        text-align: center;
        margin-top: 5vh;
        margin-bottom: 1vh;
    }

    .containerTextThree {
        width: 100%;

    }

    .smallMovilOne {

        width: 38vw;
        height: 18vh;

        position: relative;
    }

    .containerSmallPhotoMap {
        border-radius: 10px;
        width: 25vw;
        height: 15vh;
        background-color: #9FFFF0;
        display: flex;
        align-items: center;
        justify-content: center;


    }

    .selected {
        width: 28vw;
        height: 22vh;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 5px 3px rgb(0, 0, 0, 0.3);
    }

    .contaimerSmallPhotoNotSelect {

        border-radius: 10px;
        width: 25vw;
        height: 20vh;
        background-color: #E0DFDF;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .firstArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -1vh;
        left: -25vw;
        transform: rotate(51deg) scaleY(-1);
        filter: grayscale(1) brightness(0.5);


    }

    .containerPhoneMoreParagraph {
        margin: 110px 0px 140px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80vh;
        background-color: transparent;
        flex-direction: column;



    }

    .paragraphOneMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.5;
        top: 10vh;
        position: relative;
        padding: 0px 0px 30px 20px;

    }

    .paragraphTwoMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.6;
        top: 10vh;
        position: relative;
        padding: 0px 0px 20px 20px;
        margin-top: -2vh;

    }

    .secondArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -2vh;
        left: 3vw;
        transform: rotate(-135deg);
        filter: grayscale(1) brightness(0.5);

    }

    .thirdArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -1vh;
        left: 29vw;
        transform: rotate(145deg);
        filter: grayscale(1) brightness(0.5);
    }

    .leftOverTextContainer {

        margin-bottom: -3vh;

    }


}


@media only screen and (min-width:360px) and (min-height:740px) and (max-width:370px) and (max-height:800px) {


    .container {
        display: none;
    }

    .arrowDown {
        display: none;
    }

    .arrowDownSecond {
        display: none;
    }

    .arrowDownThree {
        display: none;
    }

    .containerParagraph {
        display: none;
    }

    .containerParagraphTwo {
        display: none;
    }

    .containerParagraphThree {
        display: none;
    }

    .containerBannerPhotoMovil {

        display: flex;
        background-color: transparent;
        width: 100%;
        height: 28vh;
        align-items: center;
        justify-content: center;
    }

    .containerPhotos {
        display: none;
    }

    .fatherContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 45vh;
        padding-bottom: 0vh;
    }

    .containerTextTwoForMoreText {
        width: 64%;
    }

    .containerTextTwo {
        height: 3vh;
        width: 179px;
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 1vw;
        align-items: center;
    }

    .containerTextThreee {
        height: 3vh;
        /* width: 189px; */
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 1vw;
        padding: 5px;

        align-items: center;
    }



    .letterContainer {
        margin-top: -55vh;
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: left;
    }

    .textTwoContainer {
        font-size: 15px;
        font-family: 'Poppins-Bold';
    }

    .textOneContainer {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.6rem;
        margin-bottom: -1vh;
    }

    .textThree {
        font-size: 16px;
        margin-bottom: -4vh;
    }

    .textNew {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-family: 'Poppins-Bold';
        line-height: 10vh;
        text-align: center;
        margin-top: 5vh;
        margin-bottom: 1vh;
    }

    .containerTextThree {
        width: 100%;

    }

    .smallMovilOne {

        width: 38vw;
        height: 18vh;

        position: relative;
    }

    .containerSmallPhotoMap {
        border-radius: 10px;
        width: 25vw;
        height: 15vh;
        background-color: #9FFFF0;
        display: flex;
        align-items: center;
        justify-content: center;


    }

    .selected {
        width: 28vw;
        height: 22vh;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 5px 3px rgb(0, 0, 0, 0.3);
    }

    .contaimerSmallPhotoNotSelect {

        border-radius: 10px;
        width: 25vw;
        height: 20vh;
        background-color: #E0DFDF;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .firstArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -4vh;
        left: -25vw;
        transform: rotate(51deg) scaleY(-1);
        filter: grayscale(1) brightness(0.5);


    }

    .containerPhoneMoreParagraph {
        margin: 110px 0px 140px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 55vh;
        background-color: transparent;
        flex-direction: column;



    }

    .paragraphOneMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.5;
        top: 10vh;
        position: relative;
        padding: 0px 0px 30px 20px;

    }

    .paragraphTwoMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.6;
        top: 10vh;
        position: relative;
        padding: 0px 0px 20px 20px;
        margin-top: -2vh;

    }

    .secondArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -4vh;
        left: 3vw;
        transform: rotate(-135deg);
        filter: grayscale(1) brightness(0.5);

    }

    .thirdArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -4vh;
        left: 29vw;
        transform: rotate(145deg);
        filter: grayscale(1) brightness(0.5);
    }

    .leftOverTextContainer {

        margin-bottom: -3vh;

    }

}


@media only screen and (min-width:430px) and (min-height:932px) and (max-width:500px) and (max-height:1000px) {
    .container {
        display: none;
    }

    .arrowDown {
        display: none;
    }

    .arrowDownSecond {
        display: none;
    }

    .arrowDownThree {
        display: none;
    }

    .containerParagraph {
        display: none;
    }

    .containerParagraphTwo {
        display: none;
    }

    .containerParagraphThree {
        display: none;
    }

    .containerBannerPhotoMovil {

        display: flex;
        background-color: transparent;
        width: 100%;
        height: 28vh;
        align-items: center;
        justify-content: center;
    }

    .containerPhotos {
        display: none;
    }

    .fatherContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 55vh;
        margin-bottom: -65vh;

    }

    .containerTextTwoForMoreText {
        width: 64%;
    }

    .containerTextTwo {
        height: 3vh;
        width: 179px;
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 0vw;
        align-items: center;
    }

    .containerTextThreee {
        height: 3vh;
        /* width: 189px; */
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 0vw;
        padding: 5px;

        align-items: center;
    }



    .letterContainer {
        margin-top: -76vh;
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: left;
    }

    .textTwoContainer {
        font-size: 15px;
        font-family: 'Poppins-Bold';
    }

    .textOneContainer {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.6rem;
        margin-bottom: -1vh;
    }

    .textThree {
        font-size: 16px;
        margin-bottom: -4vh;
    }

    .textNew {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-family: 'Poppins-Bold';
        line-height: 10vh;
        text-align: center;
        margin-top: 5vh;
        margin-bottom: 1vh;
    }

    .containerTextThree {
        width: 100%;

    }

    .smallMovilOne {

        width: 38vw;
        height: 18vh;

        position: relative;
    }

    .containerSmallPhotoMap {
        border-radius: 10px;
        width: 25vw;
        height: 15vh;
        background-color: #9FFFF0;
        display: flex;
        align-items: center;
        justify-content: center;


    }

    .selected {
        width: 28vw;
        height: 22vh;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 5px 3px rgb(0, 0, 0, 0.3);
    }

    .contaimerSmallPhotoNotSelect {

        border-radius: 10px;
        width: 25vw;
        height: 20vh;
        background-color: #E0DFDF;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .firstArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -4vh;
        left: -25vw;
        transform: rotate(51deg) scaleY(-1);
        filter: grayscale(1) brightness(0.5);


    }

    .containerPhoneMoreParagraph {
        margin: -15px 0px 140px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 70vh;
        background-color: transparent;
        flex-direction: column;

    }

    .paragraphOneMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.5;
        top: 10vh;
        position: relative;
        padding: 0px 0px 55px 20px;


    }

    .paragraphTwoMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.6;
        top: 10vh;
        position: relative;
        padding: 0px 0px 55px 20px;
        margin-top: -2vh;

    }

    .secondArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -4vh;
        left: 3vw;
        transform: rotate(-135deg);
        filter: grayscale(1) brightness(0.5);

    }

    .thirdArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -4vh;
        left: 29vw;
        transform: rotate(145deg);
        filter: grayscale(1) brightness(0.5);
    }

    .leftOverTextContainer {

        margin-bottom: -3vh;

    }

}

@media (max-width: 500px) {
    .containerAvatar1 {
        display: flex;
        position: absolute;
        bottom: -10px;
        left: 25px;
        margin-top: 0vh;
        transform: rotate(-5deg);
    }

    .fatherContainer {
        padding-top: 5%;
    }

    .containerAvatar2 {
        display: flex;
        position: absolute;
        top: 50px;
        right: 25px;
        margin-top: 15px;
        transform: rotate(-5deg);
    }

    .avatarStyle {
        width: 50px;
    }

    .avatar1 {
        transform: rotate(-10deg);
    }

    .avatar2 {
        transform: rotate(10deg);
    }


    .fatherContainer {
        overflow: visible;
        position: relative;
    }
}

@media only screen and (max-width:500px) and (max-height:649px) {


    .container {
        display: none;
    }

    .arrowDown {
        display: none;
    }

    .arrowDownSecond {
        display: none;
    }

    .arrowDownThree {
        display: none;
    }

    .containerParagraph {
        display: none;
    }

    .containerParagraphTwo {
        display: none;
    }

    .containerParagraphThree {
        display: none;
    }

    .containerBannerPhotoMovil {

        display: flex;
        background-color: transparent;
        width: 100%;
        height: 28vh;
        align-items: center;
        justify-content: center;
    }

    .containerPhotos {
        display: none;
    }

    .fatherContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 75vh;
        /* padding-bottom: 15vh; */
        padding-bottom: 0px;
    }

    .containerTextTwoForMoreText {
        width: 66%;
        margin-left: calc(0vh * 1);
        background-color: transparent;
        display: flex;
        align-items: left;
        justify-content: left;
    }

    .containerTextTwo {
        height: 3vh;
        width: 179px;
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 20px;
        margin-left: 5px;
        align-items: center;
    }

    .containerTextThreee {
        height: 3vh;
        /* width: 189px; */
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 1vw;
        padding: 5px;

        align-items: center;
    }



    .letterContainer {
        margin-top: -70vh;
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: left;
    }

    .textTwoContainer {
        font-size: 15px;
        font-family: 'Poppins-Bold';
    }

    .textOneContainer {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.6rem;
        margin-bottom: -1vh;
    }

    .textThree {
        font-size: 16px;
        /* margin-bottom: -4vh; */
        margin-bottom: 15px;
        margin-top: 20px;
    }

    .textNew {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-family: 'Poppins-Bold';
        line-height: 10vh;
        text-align: center;
        margin-top: 5vh;
        margin-bottom: 1vh;
    }

    .containerTextThree {
        width: 100%;

    }

    .smallMovilOne {

        width: 38vw;
        height: 18vh;

        position: relative;
    }

    .containerSmallPhotoMap {
        border-radius: 10px;
        width: 25vw;
        height: 15vh;
        background-color: #9FFFF0;
        display: flex;
        align-items: center;
        justify-content: center;


    }

    .selected {
        width: 28vw;
        height: 22vh;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 5px 3px rgb(0, 0, 0, 0.3);
    }

    .contaimerSmallPhotoNotSelect {

        border-radius: 10px;
        width: 25vw;
        height: 20vh;
        background-color: #E0DFDF;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .firstArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: calc(50% - 80vh);
        left: calc(50% - 75vw);
        transform: rotate(51deg) scaleY(-1);
        filter: grayscale(1) brightness(0.5);
    }


    .containerPhoneMoreParagraph {
        margin: calc(52% - 21vh) 0px 140px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80vh;
        background-color: transparent;
        flex-direction: column;



    }

    .paragraphOneMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.5;
        top: 10vh;
        position: relative;
        padding: 0px 0px 30px 20px;

    }

    .paragraphTwoMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.6;
        top: 10vh;
        position: relative;
        padding: 0px 0px 20px 20px;
        margin-top: -2vh;

    }

    .secondArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: calc(50% - 80vh);
        left: calc(50% - 45vw);
        transform: rotate(-135deg);
        filter: grayscale(1) brightness(0.5);

    }

    .thirdArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: calc(50% - 80vh);
        left: calc(50% - 15vw);
        transform: rotate(145deg);
        filter: grayscale(1) brightness(0.5);
    }

    .leftOverTextContainer {

        margin-bottom: -3vh;

    }



}

@media only screen and (max-width:500px) and (min-height:650px) and (max-height:750px) {
    .containerAvatar1 {
        display: none;
        position: absolute;
        bottom: 100px;
        left: 25px;
        margin-top: 0vh;
        transform: rotate(-5deg);
    }

    .containerAvatar2 {
        display: flex;
        position: absolute;
        top: 3.5%;
        right: 15px;
        margin-top: 15px;
        transform: rotate(-5deg);
    }

    .container {
        display: none;
    }

    .arrowDown {
        display: none;
    }

    .arrowDownSecond {
        display: none;
    }

    .arrowDownThree {
        display: none;
    }

    .containerParagraph {
        display: none;
    }

    .containerParagraphTwo {
        display: none;
    }

    .containerParagraphThree {
        display: none;
    }

    .containerBannerPhotoMovil {

        display: flex;
        background-color: transparent;
        width: 100%;
        height: 28vh;
        align-items: center;
        justify-content: center;
    }

    .containerPhotos {
        display: none;
    }


    .fatherContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 75vh;
        padding-bottom: 5vh;
        margin-bottom: -55vh;
    }

    .containerTextTwoForMoreText {
        width: 100%;
        padding-left: calc(30vw * 1);
        background-color: transparent;

    }

    .containerTextTwo {
        height: 3vh;
        width: 179px;
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 2vw;
        align-items: center;
    }

    .containerTextThreee {
        height: 3vh;
        /* width: 189px; */
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 1vw;
        padding: 5px;

        align-items: center;
    }



    .letterContainer {
        margin-top: -85vh;
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: left;
    }

    .textTwoContainer {
        font-size: 15px;
        font-family: 'Poppins-Bold';
    }

    .textOneContainer {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.6rem;
        margin-bottom: -1vh;
    }

    .textThree {
        font-size: 16px;
        margin-bottom: -4vh;
    }

    .textNew {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-family: 'Poppins-Bold';
        line-height: 10vh;
        text-align: center;
        margin-top: 5vh;
        margin-bottom: 1vh;
    }

    .containerTextThree {
        width: 100%;

    }

    .smallMovilOne {

        width: 38vw;
        height: 18vh;

        position: relative;
    }

    .containerSmallPhotoMap {
        border-radius: 10px;
        width: 25vw;
        height: 15vh;
        background-color: #9FFFF0;
        display: flex;
        align-items: center;
        justify-content: center;


    }

    .selected {
        width: 28vw;
        height: 22vh;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 5px 3px rgb(0, 0, 0, 0.3);
    }

    .contaimerSmallPhotoNotSelect {

        border-radius: 10px;
        width: 25vw;
        height: 20vh;
        background-color: #E0DFDF;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .firstArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -4vh;
        left: -25vw;
        transform: rotate(51deg) scaleY(-1);
        filter: grayscale(1) brightness(0.5);


    }

    .containerPhoneMoreParagraph {
        margin: 40px 0px 140px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80vh;
        background-color: transparent;
        flex-direction: column;



    }

    .paragraphOneMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.5;
        top: 10vh;
        position: relative;
        padding: 0px 0px 30px 20px;

    }

    .paragraphTwoMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.6;
        top: 10vh;
        position: relative;
        padding: 0px 0px 20px 20px;
        margin-top: -2vh;

    }

    .secondArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -4vh;
        left: 3vw;
        transform: rotate(-135deg);
        filter: grayscale(1) brightness(0.5);

    }

    .thirdArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -4vh;
        left: 29vw;
        transform: rotate(145deg);
        filter: grayscale(1) brightness(0.5);
    }

    .leftOverTextContainer {

        margin-bottom: -3vh;

    }



}

@media only screen and (max-width:500px) and (min-height:751px) and (max-height:800px) {
    .containerAvatar1 {
        display: flex;
        position: absolute;
        bottom: 110px;
        left: 25px;
        margin-top: 0vh;
        transform: rotate(-5deg);
    }

    .avatar1 {
        width: 75px;
    }


    .containerAvatar2 {
        display: flex;
        position: absolute;
        top: 5.5%;
        right: 15px;
        margin-top: 15px;
        transform: rotate(-5deg);
    }

    .container {
        display: none;
    }

    .arrowDown {
        display: none;
    }

    .arrowDownSecond {
        display: none;
    }

    .arrowDownThree {
        display: none;
    }

    .containerParagraph {
        display: none;
    }

    .containerParagraphTwo {
        display: none;
    }

    .containerParagraphThree {
        display: none;
    }

    .containerBannerPhotoMovil {

        display: flex;
        background-color: transparent;
        width: 100%;
        height: 28vh;
        align-items: center;
        justify-content: center;
    }

    .containerPhotos {
        display: none;
    }

    .fatherContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 75vh;
        padding-bottom: 0vh;
        margin-bottom: -40vh;
    }

    .containerTextTwoForMoreText {
        width: 64%;
    }

    .containerTextTwo {
        height: 3vh;
        width: 179px;
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 1vw;
        align-items: center;
    }

    .containerTextThreee {
        height: 3vh;
        /* width: 189px; */
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 1vw;
        padding: 5px;

        align-items: center;
    }



    .letterContainer {
        margin-top: -85vh;
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: left;
    }

    .textTwoContainer {
        font-size: 15px;
        font-family: 'Poppins-Bold';
    }

    .textOneContainer {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.6rem;
        margin-bottom: -1vh;
    }

    .textThree {
        font-size: 16px;
        margin-bottom: -4vh;
    }

    .textNew {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-family: 'Poppins-Bold';
        line-height: 10vh;
        text-align: center;
        margin-top: 5vh;
        margin-bottom: 1vh;
    }

    .containerTextThree {
        width: 100%;

    }

    .smallMovilOne {

        width: 38vw;
        height: 18vh;

        position: relative;
    }

    .containerSmallPhotoMap {
        border-radius: 10px;
        width: 25vw;
        height: 15vh;
        background-color: #9FFFF0;
        display: flex;
        align-items: center;
        justify-content: center;


    }

    .selected {
        width: 28vw;
        height: 22vh;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 5px 3px rgb(0, 0, 0, 0.3);
    }

    .contaimerSmallPhotoNotSelect {

        border-radius: 10px;
        width: 25vw;
        height: 20vh;
        background-color: #E0DFDF;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .firstArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -5vh;
        left: -25vw;
        transform: rotate(51deg) scaleY(-1);
        filter: grayscale(1) brightness(0.5);


    }

    .containerPhoneMoreParagraph {
        margin: -5px 0px 140px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80vh;
        background-color: transparent;
        flex-direction: column;

    }

    .paragraphOneMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.5;
        top: 10vh;
        position: relative;
        padding: 0px 0px 30px 20px;

    }

    .paragraphTwoMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.6;
        top: 10vh;
        position: relative;
        padding: 0px 0px 30px 20px;
        margin-top: -2vh;

    }

    .secondArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -5vh;
        left: 3vw;
        transform: rotate(-135deg);
        filter: grayscale(1) brightness(0.5);

    }

    .thirdArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -5vh;
        left: 29vw;
        transform: rotate(145deg);
        filter: grayscale(1) brightness(0.5);
    }

    .leftOverTextContainer {

        margin-bottom: -3vh;

    }



}

@media only screen and (max-width:500px) and (min-height:800px) and (max-height:850px) {
    .containerAvatar1 {
        display: none;
    }

    .containerAvatar2 {
        display: flex;
        position: absolute;
        top: 5.5%;
        right: 15px;
        margin-top: 15px;
        transform: rotate(-5deg);
    }

    .container {
        display: none;
    }

    .arrowDown {
        display: none;
    }

    .arrowDownSecond {
        display: none;
    }

    .arrowDownThree {
        display: none;
    }

    .containerParagraph {
        display: none;
    }

    .containerParagraphTwo {
        display: none;
    }

    .containerParagraphThree {
        display: none;
    }

    .containerBannerPhotoMovil {

        display: flex;
        background-color: transparent;
        width: 100%;
        height: 28vh;
        align-items: center;
        justify-content: center;
    }

    .containerPhotos {
        display: none;
    }

    .fatherContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 75vh;
        padding-bottom: 0vh;
        margin-bottom: -65vh;
    }

    .containerTextTwoForMoreText {
        width: 64%;
    }

    .containerTextTwo {
        height: 3vh;
        width: 179px;
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 1vw;
        align-items: center;
    }

    .containerTextThreee {
        height: 3vh;
        /* width: 189px; */
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 1vw;
        padding: 5px;

        align-items: center;
    }



    .letterContainer {
        margin-top: -85vh;
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: left;
    }

    .textTwoContainer {
        font-size: 15px;
        font-family: 'Poppins-Bold';
    }

    .textOneContainer {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.6rem;
        margin-bottom: -1vh;
    }

    .textThree {
        font-size: 16px;
        margin-bottom: -4vh;
    }

    .textNew {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-family: 'Poppins-Bold';
        line-height: 10vh;
        text-align: center;
        margin-top: 5vh;
        margin-bottom: 1vh;
    }

    .containerTextThree {
        width: 100%;

    }

    .smallMovilOne {

        width: 38vw;
        height: 18vh;

        position: relative;
    }

    .containerSmallPhotoMap {
        border-radius: 10px;
        width: 25vw;
        height: 15vh;
        background-color: #9FFFF0;
        display: flex;
        align-items: center;
        justify-content: center;


    }

    .selected {
        width: 28vw;
        height: 22vh;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 5px 3px rgb(0, 0, 0, 0.3);
    }

    .contaimerSmallPhotoNotSelect {

        border-radius: 10px;
        width: 25vw;
        height: 20vh;
        background-color: #E0DFDF;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .firstArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -5vh;
        left: -25vw;
        transform: rotate(51deg) scaleY(-1);
        filter: grayscale(1) brightness(0.5);


    }

    .containerPhoneMoreParagraph {
        margin: -38px 0px 140px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80vh;
        background-color: transparent;
        flex-direction: column;

    }

    .paragraphOneMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.5;
        top: 10vh;
        position: relative;
        padding: 0px 0px 30px 20px;

    }

    .paragraphTwoMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.6;
        top: 10vh;
        position: relative;
        padding: 0px 0px 30px 20px;
        margin-top: -2vh;

    }

    .secondArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -5vh;
        left: 3vw;
        transform: rotate(-135deg);
        filter: grayscale(1) brightness(0.5);

    }

    .thirdArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -5vh;
        left: 29vw;
        transform: rotate(145deg);
        filter: grayscale(1) brightness(0.5);
    }

    .leftOverTextContainer {

        margin-bottom: -3vh;

    }



}


@media only screen and (max-width:500px) and (min-height:850px) and (max-height:1000px) {
    .fatherContainer {
        display: relative;
    }

    .containerAvatar1 {
        display: none;
        position: absolute;
        bottom: 0;
        left: 25px;
        margin-top: 0vh;
        transform: rotate(-5deg);
    }

    .containerAvatar2 {
        display: flex;
        position: absolute;
        top: 5.5%;
        right: 15px;
        margin-top: 15px;
        transform: rotate(-5deg);
    }

    .container {
        display: none;
    }

    .arrowDown {
        display: none;
    }

    .arrowDownSecond {
        display: none;
    }

    .arrowDownThree {
        display: none;
    }

    .containerParagraph {
        display: none;
    }

    .containerParagraphTwo {
        display: none;
    }

    .containerParagraphThree {
        display: none;
    }

    .containerBannerPhotoMovil {

        display: flex;
        background-color: transparent;
        width: 100%;
        height: 28vh;
        align-items: center;
        justify-content: center;
    }

    .containerPhotos {
        display: none;
    }

    .fatherContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 75vh;
        padding-bottom: 0vh;
        margin-bottom: -75vh;
    }

    .containerTextTwoForMoreText {
        width: 64%;
        display: block;
        align-items: left;
        justify-content: left;
        margin-left: calc(-3vw * 1);

    }

    .containerTextTwo {
        height: 3vh;
        width: 179px;
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 1vw;
        align-items: center;
    }

    .containerTextThreee {
        height: 3vh;
        /* width: 189px; */
        background-color: #00FFD7;
        border-radius: 25px;
        display: flex;
        margin-top: 1vh;
        margin-left: 1vw;
        padding: 5px;

        align-items: center;
    }



    .letterContainer {
        margin-top: -105vh;
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: left;
    }

    .textTwoContainer {
        font-size: 15px;
        font-family: 'Poppins-Bold';
    }

    .textOneContainer {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.6rem;
        margin-bottom: -1vh;
    }

    .textThree {
        font-size: 16px;
        margin-bottom: -4vh;
    }

    .textNew {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-family: 'Poppins-Bold';
        line-height: 10vh;
        text-align: center;
        margin-top: 5vh;
        margin-bottom: 1vh;
    }

    .containerTextThree {
        width: 100%;

    }

    .smallMovilOne {

        width: 38vw;
        height: 18vh;

        position: relative;
    }

    .containerSmallPhotoMap {
        border-radius: 10px;
        width: 25vw;
        height: 15vh;
        background-color: #9FFFF0;
        display: flex;
        align-items: center;
        justify-content: center;


    }

    .selected {
        width: 28vw;
        height: 22vh;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 5px 5px 5px 3px rgb(0, 0, 0, 0.3);
    }

    .contaimerSmallPhotoNotSelect {

        border-radius: 10px;
        width: 25vw;
        height: 20vh;
        background-color: #E0DFDF;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

    }



    .firstArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -5vh;
        left: -25vw;
        transform: rotate(51deg) scaleY(-1);
        filter: grayscale(1) brightness(0.5);


    }

    .containerPhoneMoreParagraph {
        margin: -57px 0px 140px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80vh;
        background-color: transparent;
        flex-direction: column;

    }

    .paragraphOneMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.5;
        top: 10vh;
        position: relative;
        padding: 0px 0px 30px 20px;

    }

    .paragraphTwoMovilDesign {
        text-align: center;
        font-size: 1rem;
        font-family: 'RockSalt-Regular';
        line-height: 1.6;
        top: 10vh;
        position: relative;
        padding: 0px 0px 30px 20px;
        margin-top: -2vh;

    }

    .secondArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -5vh;
        left: 3vw;
        transform: rotate(-135deg);
        filter: grayscale(1) brightness(0.5);

    }

    .thirdArrowMovil {
        display: flex;
        width: 12vw;
        position: relative;
        top: -5vh;
        left: 29vw;
        transform: rotate(145deg);
        filter: grayscale(1) brightness(0.5);
    }

    .leftOverTextContainer {

        margin-bottom: -3vh;

    }



}