@import url('../../fonts/font.css');



.container {
    position: relative;
    width: 50%;
    height: 100vh;
    background-color: white;
    margin-top: 5%;
}

.fatherContainer {
    align-items: center;
    justify-content: center;
    margin-top: -50vh;
    width: 100%;
    height: 150vh;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.letterContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
    justify-content: center;
    margin-top: 9.5%;

}


.containerPhotos {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.containerPhotos img {
    width: 80%;
}

.imageMovilOne {
    z-index: 1;
    width: 70% !important;
    height: auto;
    bottom: 9%;
    left: 28%;
    position: relative;
    animation: elipse 5s ease-in-out infinite;

}


.containerPhotos img:nth-child(2) {
    z-index: 2;
    width: 69%;
    bottom: 9%;
    right: 12%;
    position: relative;
    animation: elipse 5s ease-in-out infinite;

}

.containerPhotos img:nth-child(3) {
    z-index: 3;
    width: 60%;
    bottom: 8%;
    right: 53%;
    position: relative;
    animation: elipse 5s ease-in-out infinite;

}




.elipseContainer {
    display: flex;
    position: absolute;
    width: 170%;
    height: 50%;
    background-color: transparent;
    left: -25%;
    overflow: hidden;


}

.elipse {
    position: absolute;
    top: -10%;
    height: 60vh;
    width: 25%;
    animation: elipse 5s ease-in-out infinite;
    /* Aplica la animación */

}


.containerParagraph {
    position: absolute;
    top: 82%;
    width: 45%;
    left: 1%;

}

.containerParagraphTwo {
    position: absolute;
    top: 0%;
    width: 48%;
    left: 20%;

}

.containerParagraphThree {
    position: absolute;
    top: 87%;
    width: 40%;
    left: 55%;

}


.paragraphSecond {
    text-align: left;
    font-size: 1rem;
    font-family: 'RockSalt-Regular';
    transform: rotate(-10deg);
    line-height: 1.6;

}


.paragraphOne {
    text-align: left;
    font-size: 1rem;
    font-family: 'RockSalt-Regular';
    transform: rotate(-10deg);
    line-height: 1.6;

}

.arrowDown {
    position: absolute;
    top: 75%;
    left: -2%;
    width: 8%;
    transform: rotate(-15deg);
    filter: brightness(0);

}

.arrowDownSecond {
    position: absolute;
    top: 10%;
    left: 55%;
    width: 8%;
    transform: rotate(155deg);
    filter: brightness(0);


}

.arrowDownThree {
    position: absolute;
    top: 78%;
    left: 54.5%;
    width: 8%;
    /* se gira horizontalmente */

    transform: rotate(250deg) scaleY(-1);

    filter: brightness(0);

}


.textOneContainer {
    word-wrap: break-word;
    line-height: 2rem;
    font-family: 'Poppins-Regular';
    font-size: 2rem;
    text-align: left;
    position: relative;
    bottom: 10%;

}

.containerTextOne {

    width: 74%;
    margin-top: 5rem;

}

.moreTextContainer {
    font-family: 'Poppins-Regular';
    font-size: 30px;
    text-align: left;
    margin-top: -2%;
}

.containerTextTwoForMoreText {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 3rem;
}

.containerTextTwo {
    height: 7%;
    width: 50%;
    background-color: #00FFD7;
    border-radius: 25px;
    padding-right: 3%;
    padding: 1%;
    display: flex;
    align-items: center;
}

.containerTextThreee {
    height: 7%;
    width: 38%;
    background-color: #00FFD7;
    border-radius: 25px;
    padding-right: 3%;
    padding: 1%;
    display: flex;
    align-items: center;
}

.textTwoContainer {

    font-family: 'Poppins-Regular';
    font-size: 30px;
    text-align: left;



}

.leftOverTextContainer {
    width: 75%;
    text-align: left;

}

.textThree {

    font-family: 'Poppins-Regular';
    font-size: 20px;
}


.containerBannerPhotoMovil {
    display: none;
}


.containerTextThreee {
    width: auto;
    font-weight: bold;
}

@keyframes elipse {
    0% {
        transform: translateY(0);
        /* Posición inicial */
    }

    50% {
        transform: translateY(-20px);
        /* Subir la elipse */
    }

    100% {
        transform: translateY(0);
        /* Volver a la posición inicial */
    }
}

.textNew {
    display: none;
}

.firstArrowMovil {
    display: none;
}

.containerPhoneMoreParagraph {
    display: none;
}


.secondArrowMovil {
    display: none;
}

.thirdArrowMovil {

    display: none;
}

@keyframes moveArrow {
    0% {
        transform: translateX(0) rotate(10deg);
        /* Posición inicial */
    }

    50% {
        transform: translateX(-10px) rotate(10deg);
        /* Subir la flecha */
    }

    100% {
        transform: translateX(0) rotate(10deg);
        /* Volver a la posición original */
    }
}


.containerAvatar1 {
    /* display: flex; */
    display: flex;
    position: absolute;
    bottom: -60px;
    left: calc(30vh * 0.5);
    margin-top: 0vh;
    transform: rotate(-5deg);
    z-index: 999;
}

.containerAvatar2 {
    display: flex;
    position: absolute;
    top: 10px;
    right: 85px;
    margin-top: 15px;
    transform: rotate(-5deg);
    z-index: 999;
}

.avatarStyle {
    width: 75px;
}

.avatar1 {
    transform: rotate(-10deg);
}

.avatar2 {
    transform: rotate(10deg);
}


.fatherContainer {
    overflow: visible;
    position: relative;
}