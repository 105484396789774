@font-face {
    font-family: 'Rock Salt';
    src: url('./Fonts/RockSalt-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins Regular';
    src: url('./Fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins Bold';
    src: url('./Fonts/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: url('./Fonts/Roboto-Bold.ttf') format('truetype');
}

/* trae Alkatra-Regular.ttf */
@font-face {
    font-family: 'Alkatra';
    src: url('./Fonts/Alkatra-Regular.ttf') format('truetype');
}

.cajaTelefono {
    display: flex;
    flex-direction: column;
    /* position: relative; */
}

/* botones play */
.play-pause-switch {
    position: absolute;
    top: 45%;
    left: calc(50% - 25px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.2);
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    /* Reducido a 0.3s para una respuesta más rápida */
    opacity: 0;
    /* Inicialmente oculto */
    z-index: 2;
    font-size: 24px;
    color: white;
    /* Color del ícono */
}

.botonPlayMobile {
    background-color: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.1);

}

/* Clases para controlar la visibilidad */
.play-pause-switch.hidden {
    opacity: 0;
    /* Oculta el botón */
}

.play-pause-switch.visible {
    opacity: 1;
    /* Muestra el botón */
}

.expand-button.visible {
    opacity: 1;
}

.expand-button.hidden {
    opacity: 0;
}

.expand-button {
    position: absolute;
    top: 40px;
    right: calc(50% - 15px);
    width: 30px; /* Tamaño más pequeño */
    height: 30px; /* Tamaño cuadrado */
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    border-radius: 50%; /* Forma circular para estilo minimalista */
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease, opacity 0.3s ease;
}

.expand-button-expanded {
    top: 30px;
}

.expand-button:hover {
    background-color: rgba(255, 255, 255, 0.4);
}

.expand-button:active {
    transform: scale(0.9);
}

.expand-icon {
    color: white;
    width: 20px; /* Tamaño del ícono */
    height: 20px;
}


.phoneDeskBloq1.expanded .phone-frame {
    position: fixed;
    top: -15px;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    z-index: 1001;
}

.phoneView2Bloq1.expanded .phone-content {
    position: fixed;
    top: -0px;
    left: 0;
    width: 100vw;
    height: 99vh;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    z-index: 1001;
}

/* Efecto de hover */
.play-pause-switch:hover {
    background-color: rgba(255, 255, 255, 0.4);
}

.play-pause-switch:active {
    transform: scale(0.9);
}

/* botones pausa */

.botonPhone {
    display: none;
}

.mute-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background-color: rgba(68, 68, 68, 0.4);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    bottom: 2px;
    right: calc(50% - 12.5px);
    z-index: 2;
    font-size: 14px;
    /* Ajusta el tamaño del icono */
    color: white;
    line-height: 1;
    /* Asegura que el icono se centre verticalmente */
}

.mute-expanded {
    bottom: 10px;
}




.mute-switch.muted {
    /* background-color: #ff4d4d; */
    /* Color rojo para muted */
    color: white;
}

.mute-switch.unmuted {
    display: none;
    /* background-color: #00FFD7; */
    /* Color verde para unmuted */
}

.mute-switch:hover {
    background-color: rgba(68, 68, 68, 0.5);
}

.mute-switch:active {
    transform: scale(0.9);
}

.cajaTelefono {
    display: flex;
    flex-direction: column;
    /* position: relative; */
}





.phone-frame {
    margin-top: 20px;
    width: 250px;
    height: 500px;
    border: 2px solid #2e2e2e;
    border-radius: 36px;
    padding: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}



.phone-frame::before {
    content: '';
    display: block;
    width: 25px;
    height: 6px;
    background: #333;
    border-radius: 3px;
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translateX(-50%);
}

.phone-frame::after {
    content: '';
    position: absolute;
    top: 18px;
    left: calc(50% - 32px);
    display: flex;
    justify-content: space-between;
    width: 64px;
}

.phone-frame::after::before,
.phone-frame::after::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #222;
    border-radius: 50%;
    box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.5);
}

.phone-frame::after::before {
    margin-right: 20px;
}

.phone-content {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    overflow: hidden;
    background: #000;
}





/* Efecto de iluminación en los bordes del teléfono */
/* .phone-frame:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 0 18px rgba(255, 255, 255, 0.3);
    pointer-events: none;
}
 */



.fondo {
    /* height: 98vh; */
    /* position: relative; */
    height: auto;
    padding-top: 80px;
    padding-bottom: 5vh;
    background: linear-gradient(116.31deg, #161616 9.12%, #0B0B48 37.65%, #060661 47.42%, #0C0C45 65.37%, #0C0C0C 99.95%);
}

.columnas {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 70vh;
}

.spinnerBloq1 {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.columnaSingular {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 5%;
}

.rockSaltText {
    font-family: 'Rock Salt', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
}

.icon1 {
    width: 200px;
    margin-bottom: 20px;
}

.logo {
    width: 200px;
    margin-right: 15px;
}

.textoBienvenida {
    color: #ffffff;
    font-family: 'Poppins Regular', sans-serif;
    /* justifica el texto */
    text-align: left;
    font-size: 18px;
}

.textoBienvenidaBold {
    color: #ffffff;
    font-family: 'Poppins Bold', sans-serif;
}

.textoBienvenidaContraste {
    color: black;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 0 2px;
    font-family: 'Poppins Regular', sans-serif;
}

.phoneView {
    width: 68%;
    margin: 0 auto;
}

.boton {
    color: #060661;
    background-color: #ffffff;
    border-radius: 12.5px;
    padding: 5px 40px;
    width: 100%;
    text-align: center;

    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 33px;

    transition: background-color 0.3s ease, transform 0.3s ease;
}

.boton:hover {
    background-color: #00FFD7;
    transform: scale(1.05);
}

.footerText {
    font-family: Rock Salt;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    display: inline-flex;
    margin-bottom: 120px;
    margin-left: 20px;
    transform: rotate(-5deg);
}

.fila {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.arrow {
    /* gira la image 90 grados a la derecha     */
    transform: rotate(100deg);
    height: 9vh;
    margin-left: 0;
    margin-top: 20px;
}

.contador,
.numberBloq1 {
    color: #00FFD7;

    font-family: 'Poppins Regular', sans-serif;
    font-size: 1.7vw;
    font-weight: 400;
    line-height: 1.2rem;
    text-align: center;
    margin-right: 20px;
}

.numberBloq1 {
    font-size: 25px;
    margin-right: 0;
}

.contadorNumero {
    color: #00FFD7;

    margin: 0;

    font-family: 'Poppins Regular', sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.01em;
    text-align: center;

}

.contadorSubtitulo {
    color: #00FFD7;
    margin: 0;
    font-family: 'Poppins Regular', sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 14.98px;
    text-align: center;

}

.filaContador {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 00px;
    width: 100%;
    margin-bottom: 4vh;
}

.fechaContenedor {
    margin: 0 10px;
}

.subtitulo {
    color: #ffffff;
    /* poppins; */
    font-family: 'Poppins Regular', sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 51px;
    text-align: left;
    margin: 0;
}

.bloqueVerde {
    /* poppins */
    font-family: 'Poppins Regular', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 2rem;
    text-align: left;
    color: #000000;
    background-color: var(--Color-acento-landing, #00FFD7);
    display: inline;
    padding: 0 5px;
    margin: 5px 0 20px 0;
    border-radius: 10px;
}

.cajaVertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0px;
}

.textoPoppinsDefault {
    font-family: 'Poppins Regular', sans-serif;
    text-align: left;
    font-weight: 400;
    color: #ffffff;
    margin: 0;
    font-size: 18px;
    margin-bottom: 2.5vh;
}

.textoFooter {
    font-family: Alkatra;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    margin: 20px 0 10px 0;
}

footer {
    width: 20%;
    margin: 0 auto;
}

.arrowContainer {
    /* lo pongo en horizontal */
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.arrowText {
    color: #ffffff;
    font-family: 'Rock Salt', sans-serif;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    font-size: 18px;
    margin-bottom: 10px;
}

.leftBloq1 {
    width: 50%;
    margin-right: 10%;
}

.phoneView2Bloq1 {
    display: none;
}

.rightBloq1 {
    /* transform: translateX(100%);
    opacity: 0;
    transition: transform 1.5s ease-out, opacity 0.5s ease-out; */
}

.rightBloq1.active {
    transform: translateX(0);
    opacity: 1;
}

/* @media (max-width: 500px) {
    .fondo {
        padding-top: 30px;
    }
} */


@media (min-width:360px) and (min-height:640px) and (max-width: 500px) {

    .fondo {
        padding-top: 3vh;
        padding-bottom: 6vh;
    }


}

@media only screen and (min-width:360px) and (min-height:740px) and (max-width:370px) and (max-height:800px) {
    .fondo {
        margin-bottom: -15vh;
    }
}

/* @media (min-width:375px) and (min-height:667px) {

    .fondo {
        padding-top: 0vh;
    }

} */

@media only screen and (min-width:430px) and (min-height:932px) and (max-width:500px) and (max-height:1000px) {

    .fondo {
        margin-bottom: -30vh;
    }


}


@media (max-width:500px) and (min-height:700px) and (max-height:1000px) {
    .fondo {
        padding-top: 3vh;
        padding-bottom: 6vh;
        margin-bottom: -15vh;
    }

}


/* diseño mobile */
@media (max-width: 500px) {
    .botonDesk {
        display: none;
    }

    .rightBloq1 {
        transform: none;
        transition: none;
    }

    .phoneDeskBloq1 {
        display: none;
    }

    .phoneView2Bloq1 {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .filaContador {
        margin-bottom: 0vh;
    }

    .textoFooter {

        margin-bottom: 1rem;
    }

    .columnas {
        flex-direction: column;
        height: auto;
    }

    .leftBloq1 {
        margin: 0;
        width: 90%;
    }

    .subtitulo {
        font-size: 1.4rem;
        line-height: 2rem;
        margin-bottom: 10px;
    }

    .bloqueVerde {
        font-size: 1rem;
        margin: 0;
        line-height: 1.5rem;
        margin-bottom: 10px;
    }

    .phoneView {
        display: none;
    }

    .fondo {
        height: auto;
        padding: 40px 0 0 0;
        padding-bottom: 15vh;
    }

    .textoBienvenida {
        font-size: 1rem;
        line-height: 1.25rem;
    }

    .columnaSingular {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        margin: 0 5%;
    }

    footer {
        width: 80%;
        padding-top: 1vh;
    }

    .columnas {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .arrowText {
        font-size: 1.5rem;
        line-height: 1.7rem;
        margin: 0;
        margin-left: 10px;
        margin-right: -10px;
    }

    .arrow {
        height: 15vh;
        margin-top: 0px;
    }

    .boton {
        margin-bottom: 8vh;
    }

    .contador,
    .numberBloq1 {
        font-size: 12px;
        
    }

    .numberBloq1 {
        font-size: 16px;
        margin-right: 4px;
    }
}


/* diseño computadora */
@media (max-width: 700px) and (min-width: 501px) {

    .contador,
    .numberBloq1 {
        font-size: 0.8rem;
    }

    .numberBloq1 {
        font-size: 1rem;
        margin-right: 0;
    }

    .contadorSubtitulo {
        font-size: 0.5rem;
    }

    .contadorNumero {
        font-size: 1rem;
    }

    .subtitulo {
        font-size: 0.8rem;
    }

    .bloqueVerde {
        font-size: 0.8rem;
        line-height: 1.4rem;
    }

    .textoPoppinsDefault {
        font-size: 0.6rem;
    }

    .textoBienvenida {
        font-size: 0.6rem;
    }

    .boton {
        font-size: 0.8rem;
        padding-top: 0;
        padding-bottom: 0;
        width: 75%;
    }

    .textoFooter {
        font-size: 0.65rem;
        line-height: 1rem;
        margin-top: 4vh
    }

    .arrowText {
        font-size: 0.8rem;
        line-height: 1.3rem;
        margin-top: 0;
    }

    .arrow {
        height: 10vh;
        margin-top: 0px;
    }

    .filaContador {
        margin-bottom: 0vh;
    }

    .fondo {
        padding-top: 1vh;
    }

    .phoneView {
        width: 55%;
        margin: 0 auto;
    }

    footer {
        width: 25%;
        margin-left: 65%;

    }

    .leftBloq1 {
        margin-top: 15vh;

    }

}

@media (max-width: 900px) and (min-width: 701px) {
    .phone-frame {
        margin-top: 20px;
        /* Reducido desde 40px */
        width: calc(200px * 0.5);
        /* Ajusta el ancho según sea necesario */
        height: 200px;
        /* Ajusta la altura según sea necesario */
        border: 1px solid #2e2e2e;
        /* Reducido desde 16px */
        border-radius: 18px;
        /* Reducido desde 36px */
        padding: 10px;
        /* Reducido desde 20px */
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2), inset 0 0 4px rgba(255, 255, 255, 0.1);
        position: relative;
        overflow: hidden;
        background-color: #000;
        /* Fondo más suave */
        background-image: linear-gradient(145deg, #000, #000);
    }

    /* Parte superior - Altavoz */
    /* Parte superior - Altavoz (notch) */
    .phone-frame::before {
        content: '';
        display: block;
        width: 10px;
        height: 3px;
        background: #2e2e2e;
        border-radius: 3px;
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
    }

    .phone-frame::after {
        position: absolute;
        top: 18px;
        left: calc(50% - 32px);
        display: flex;
        justify-content: space-between;
        width: 64px;
    }

    .phone-frame::after::before,
    .phone-frame::after::after {
        display: block;
        width: 10px;
        height: 10px;
        background: #222;
        border-radius: 50%;
        box-shadow: inset 0 0 2px rgba(255, 255, 255, 0.5);
    }

    .phone-frame::after::before {
        margin-right: 20px;
    }

    /* Contenedor de video para simular la pantalla */
    .phone-content {
        width: 100%;
        /* Ajusta según el tamaño deseado */
        height: 100%;
        border-radius: 30px;
        overflow: hidden;
        background: #000;
    }

    .contador,
    .numberBloq1 {
        font-size: 0.9rem;
    }

    footer {
        width: 25%;
        margin-left: 65%;

    }

    .contadorSubtitulo {
        font-size: 0.5rem;
    }

    .contadorNumero {
        font-size: 1rem;
    }

    .subtitulo {
        font-size: 0.8rem;
    }

    .bloqueVerde {
        font-size: 0.8rem;
        line-height: 1.4rem;
    }

    .textoPoppinsDefault {
        font-size: 0.6rem;
    }

    .textoBienvenida {
        font-size: 0.6rem;
    }

    .boton {
        font-size: 0.9rem;
        padding-top: 0;
        padding-bottom: 0;
        width: 70%;
    }

    .textoFooter {
        font-size: 0.65rem;
        line-height: 1rem;
        margin-top: 4vh
    }

    .arrowText {
        font-size: 0.8rem;
        line-height: 1.3rem;
        margin-top: 0;
    }

    .arrow {
        height: 10vh;
        margin-top: 0px;
    }

    .filaContador {
        margin-bottom: 0vh;
    }

    .fondo {
        padding-top: 1vh;
    }

    .phoneView {
        width: 55%;
        margin: 0 auto;
    }
}

@media (min-width: 901px) and (max-width: 1100px) {

    .contador,
    .numberBloq1 {
        font-size: 1rem;
    }

    .numberBloq1 {
        font-size: 1.2rem;
        margin-right: 0;
    }



    .contadorSubtitulo {
        font-size: 0.5rem;
    }

    .contadorNumero {
        font-size: 1rem;
    }

    .subtitulo {
        font-size: 25px;
    }

    .bloqueVerde {
        font-size: 18px;
        margin-bottom: 5vh;
        line-height: 1.5rem;
    }

    .textoPoppinsDefault {
        font-size: 18px;
    }

    .textoBienvenida {
        font-size: 16px;
    }

    .boton {
        font-size: 1rem;
        padding-top: 0;
        padding-bottom: 0;
    }

    .textoFooter {
        font-size: 0.65rem;
        line-height: 1rem;
    }

    .arrowText {
        font-size: 1rem;
        line-height: 1.3rem;
    }

    .arrow {
        height: 9vh;
        margin-top: 15px;
    }

    .filaContador {
        margin-bottom: 0vh;
    }


}

@media (min-width: 1600px) {

    .phone-frame {
        width: calc(500px * 0.5);
        height: 500px;
    }

    .contador,
    .numberBloq1 {
        font-size: 1.8rem;
        margin-top: 20px;
    }

    .numberBloq1 {
        font-size: 2.2rem;
        margin-right: 0;
    }



    .subtitulo {
        font-size: 40px;
    }

    .bloqueVerde {
        font-size: 35px;
        margin-bottom: 5vh;
        line-height: 3rem;
    }

    .textoPoppinsDefault {
        font-size: 20px;
    }

    .textoBienvenida {
        font-size: 20px;
    }

    .boton {
        font-size: 30px;
        padding-top: 2vh;
        padding-bottom: 2vh;
    }

    .textoFooter {
        font-size: 20px;
    }

    .arrowText {
        font-size: 25px;
        line-height: 35px;
    }

    .arrow {
        height: 10vh;
        margin-left: 0;
    }
}

@media (min-width: 1910px) and (max-height: 900px) and (min-height: 560px) {
    .phoneView {
        width: 50%;
    }

    .fondo {
        height: auto;
        padding-bottom: 5vh;
    }

    .fondo footer {
        margin-top: 15vh;

    }
}