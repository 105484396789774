.fatherContainer {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
}

.carrusel1 {
    margin-top: -45vh;
    width: 100%;
    position: relative;
    top: 55%;
    z-index: 1;
}

.carrusel2 {
    width: 100%;
    position: relative;
    top: -39vh;
    z-index: 1;
}

@media only screen and (min-width:360px) and (min-height:640px) and (max-width:374px) and (max-height:666px) {
    .carrusel1 {
        top: 30vh;
        transform: rotate(1.9deg);

    }

    .carrusel2 {
        top: -33vh;
        transform: rotate(1.9deg);
    }

}

@media only screen and (min-width:360px) and (min-height:740px) and (max-width:370px) and (max-height:800px) {

    .carrusel1 {
        top: 25vh;
        transform: rotate(1.9deg);

    }

    .carrusel2 {
        top: -29vh;
        transform: rotate(1.9deg);
    }


}


@media only screen and (min-width:375px) and (min-height:667px) and (max-height:700px) {
    .carrusel1 {
        top: 25vh;
        transform: rotate(1.9deg);

    }

    .carrusel2 {
        top: -33vh;
        transform: rotate(1.9deg);
    }

}


@media only screen and (min-width:430px) and (min-height:932px) and (max-width:500px) and (max-height:1000px) {
    .carrusel1 {
        top: 20vh;
        transform: rotate(1.9deg);

    }

    .carrusel2 {
        top: -25vh;
        transform: rotate(1.9deg);
    }
}

@media only screen and (max-width:500px) and (max-height:750px) {

    .carrusel1 {
        top: 27vh;
        transform: rotate(1.9deg);

    }

    .carrusel2 {
        top: -32.5vh;
        transform: rotate(1.9deg);
    }
}

@media only screen and (max-width:500px) and (min-height:750px) and (max-height:1000px) {
    .carrusel1 {
        top: 23vh;
        transform: rotate(1.9deg);

    }

    .carrusel2 {
        top: -27vh;
        transform: rotate(1.9deg);
    }
}


@media (max-width: 700px) and (min-width: 501px) {
    .carrusel1 {
        top: 85%;
    }

    .carrusel2 {
        top: -55%
    }
}

@media (max-width: 900px) and (max-height: 415px) and (min-width: 701px) {
    .carrusel1 {
        top: 78%;
    }

    .carrusel2 {
        top: -50%;
    }
}

@media (max-width: 900px) and (min-width: 701px) and (min-height: 416px) {
    .carrusel1 {
        top: 78%;
    }

    .carrusel2 {
        top: -46%;
    }
}

@media (max-width: 1050px) and (min-width: 901px) {
    .carrusel1 {
        top: 55vh;
    }

    .carrusel2 {
        top: -42vh;
    }
}

@media (max-width: 1100px) and (min-width: 1051px) {
    .carrusel1 {
        top: 62%;
    }

    .carrusel2 {
        top: -42vh;
    }
}

/* Resoluciones medianas */
@media only screen and (min-width: 800px) and (min-height: 1236px) {
    .carrusel1 {
        top: 37vh;
        transform: rotate(0.5deg);
    }

    .carrusel2 {
        top: -16vh;
        transform: rotate(0.5deg);
    }
}

@media only screen and (min-width: 900px) and (min-height: 1156px) {
    .carrusel1 {
        top: 35vh;
        transform: rotate(0.5deg);
    }

    .carrusel2 {
        top: -19vh;
        transform: rotate(0.5deg);
    }
}

@media only screen and (min-width: 1000px) and (min-height: 1340px) {
    .carrusel1 {
        top: 33vh;
        transform: rotate(0.5deg);
    }

    .carrusel2 {
        top: -16vh;
        transform: rotate(0.5deg);
    }
}

@media (min-width: 1004px) and (min-height: 748px) {
    .carrusel1 {
        top: 50%;
        transform: rotate(0.5deg);
    }

    .carrusel2 {
        top: -30vh;
        transform: rotate(0.5deg);
    }
}

@media screen and (min-width: 1024px) and (min-height: 1300px) {
    .carrusel1 {
        top: 33vh;
        transform: rotate(-0.5deg);
    }

    .carrusel2 {
        top: -17vh;
        transform: rotate(-0.5deg);
    }
}


@media(min-width:1366px) and (min-height:600px) {
    .carrusel1 {
        top: 57%;
        transform: rotate(-0.5deg);
    }

    .carrusel2 {
        top: -45%;
        transform: rotate(-0.5deg);

    }
}

@media(min-width:1366px) and (min-height:768px) {
    .carrusel1 {
        top: 52%;
    }

    .carrusel2 {
        top: -35%;
    }
}

@media (min-width:1300px) and (max-width:1400px) and (max-height:750px) {
    .carrusel2 {
        top: -44%;
    }
}

@media (min-width: 1350px) and (min-height: 850px) {
    .carrusel1 {
        top: 50%;
    }

    .carrusel2 {
        top: -35%;
    }
}

@media (min-width: 1419px) and (min-height: 890px) {
    .carrusel1 {
        top: 48%;
    }

    .carrusel2 {
        top: -32vh;
    }
}

@media(min-width:1419px) and (min-height:976px) {
    .carrusel1 {
        top: 45%;
    }

    .carrusel2 {
        top: -29%;
    }
}

/* Resoluciones grandes */
@media (min-width: 1501px) and (max-width: 1599px) {
    .carrusel1 {
        top: 45%;
        transform: rotate(-0.5deg);
    }

    .carrusel2 {
        top: -32%;
        transform: rotate(-0.5deg);
    }
}

@media (min-width: 1506px) and (max-width: 1606px) and (min-height: 608px) and (max-height: 707px) {
    .carrusel1 {
        top: 57%;
    }

    .carrusel2 {
        top: -44%;
    }
}

@media (min-width: 1506px) and (max-width: 1606px) and (min-height: 708px) and (max-height: 800px) {
    .carrusel1 {
        top: 56%;
        transform: rotate(-0.3deg);


    }

    .carrusel2 {
        top: -40%;
    }
}

@media (min-width: 1506px) and (max-width: 1606px) and (min-height: 801px) and (max-height: 900px) {
    .carrusel1 {
        top: 48%;
    }

    .carrusel2 {
        top: -35%;
    }
}

/* @media (min-width: 1526px) and (min-height: 844px) {
    .carrusel1 {
        top: 50vh;
    }

    .carrusel2 {
        top: -28vh;
    }
} */

@media (min-width: 1600px) and (max-height: 900px) {
    .carrusel1 {
        top: 54vh;
        transform: rotate(-0.2deg);
    }

    .carrusel2 {
        top: -37vh;
    }
}


@media (min-width: 1600px) and (max-height: 1049px) and (min-height: 901px) {
    .carrusel1 {
        top: 49vh;
    }

    .carrusel2 {
        top: -30vh;
    }
}

@media only screen and (min-width:1680px) and (min-height:1050px) {

    .carrusel1 {

        top: 45vh;

    }

    .carrusel2 {
        top: -25vh;
    }

}

@media (min-width: 1821px) and (max-width: 1909px) and (min-height: 868px) {
    .carrusel1 {
        top: 50%;
    }

    .carrusel2 {
        top: -40%;
    }
}

@media (min-width: 1910px) and (max-height: 630px) and (min-height: 560px) {
    .carrusel1 {
        top: 75%;
    }

    .carrusel2 {
        top: -53vh;
    }
}

@media (min-width: 1910px) and (max-height: 710px) and (min-height: 631px) {
    .carrusel1 {
        top: 65%;
    }

    .carrusel2 {
        top: -47vh;
    }
}

@media (min-width: 1910px) and (max-height: 800px) and (min-height: 711px) {
    .carrusel1 {
        top: 62%;
    }

    .carrusel2 {
        top: -42vh;
    }
}

@media (min-width: 1910px) and (max-height: 849px) and (min-height: 801px) {
    .carrusel1 {
        top: 53%;
    }

    .carrusel2 {
        top: -40vh;
    }
}


@media (min-width: 1910px) and (max-height: 950px) and (min-height: 850px) {
    .carrusel1 {
        top: 51%;
    }

    .carrusel2 {
        top: -36vh;
    }
}

@media (min-width: 1910px) and (min-height: 951px) and (max-height: 1099px) {
    .carrusel1 {
        top: 47%;
    }

    .carrusel2 {
        top: -32vh;
    }
}

@media (min-width: 1910px) and (max-height: 1199px) and (min-height: 1100px) {
    .carrusel1 {
        top: 42%;
    }

    .carrusel2 {
        top: -32vh;
    }
}


@media (min-width: 1910px) and (max-height: 1300px) and (min-height: 1200px) {
    .carrusel1 {
        top: 42%;
    }

    .carrusel2 {
        top: -28vh;
    }
}


@media screen and (min-width: 2000px) {
    .carrusel1 {
        top: 40%;
        transform: rotate(-0.5deg);
    }

    .carrusel2 {
        top: -29%;
        transform: rotate(-0.5deg);
    }
}




@media screen and (min-width:2600px) and (min-height:1300px) {

    .carrusel1 {
        top: 42%;
        transform: rotate(-0.5deg);
    }

    .carrusel2 {
        top: -33vh;
        transform: rotate(-0.5deg);
    }



}



@media screen and (min-width:3900px) {

    .carrusel1 {
        top: 40vh !important;
        transform: rotate(0deg);
    }

    .carrusel2 {
        top: -27vh !important;
        transform: rotate(0deg);
    }




}

@media screen and (min-width:5000px) and (min-height:2000px) {

    .carrusel1 {
        top: 37vh !important;
        transform: rotate(0deg);
    }

    .carrusel2 {
        top: -27vh !important;
        transform: rotate(0deg);
    }




}